import React, { useEffect, useState } from "react";
import useWindowWidth from "./useWindowWidth";
import { useParams } from "react-router-dom";

const AiFeedback = ({ updateComponentScoreStatus, updateUseCustomScoreFromGrading, updateComponentPointsEarned, uuid, autoAssign, scoreStatus, greyedOut, name, totalPoints, pointsEarned, output }) => {
    const width = useWindowWidth(475);
    const { courseCode, assessmentCode, email } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [error, setError] = useState(null);

    const changeAiGradingScoreStatus = async () => {
        try {
            setRequestLifeCycle(true);
            const url = process.env.REACT_APP_SUBMISSION_API_URL + `/flip-ai`
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: email,
                    uuid: uuid,
                    flipAi: !!scoreStatus
                })
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                // update the score
                setError(null);
                updateComponentScoreStatus(data.scoreStatus);
                updateComponentPointsEarned(data.pointsEarned);
                updateUseCustomScoreFromGrading(data.useCustomScore);

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                alert("Status Code: " + response.status + " Error: " + data.detail);
                setError('Failed');
            }

        } catch (error) {
            alert("Error: " + error.message);
            console.log(error);
            setError('Failed')
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        if (scoreStatus) {
            updateComponentScoreStatus(scoreStatus);
        }

    }, [scoreStatus])

    return (
        <>
            <div className="if-output-container">
                <div className="a-single-test" style={{ color: greyedOut ? "var(--gray-six)" : (pointsEarned >= totalPoints ? "var(--correct)" : "var(--incorrect)"), borderRadius: output ? "5px 5px 0px 0px" : "" }}>
                    <div className="left-of-test">
                        {!width && name}
                        {
                            !autoAssign && (
                                <div className="apply-remove" onClick={changeAiGradingScoreStatus}>
                                    {requestLifeCycle ? "Loading" : ( error ? error : (scoreStatus ? "Revert" :  "Apply"))}
                                </div>
                            )
                        }
                    </div>
                    <div className="right-of-test">
                        { (pointsEarned ? Number(pointsEarned).toFixed(2) : "0.00") + "/" + (totalPoints ? Number(totalPoints).toFixed(2) : "0.00") + " pts"}
                    </div>
                </div>
                {
                    output

                    ?

                    <div className="bottom-output" style={{ color: greyedOut ? "var(--gray-six)" : "" }}>
                        { output }
                    </div>

                    :

                    <>
                    </>

                }
            </div>
        </>
    );
}

export default AiFeedback;