import React, { useState } from "react";
import SaveChanges from "./saveChanges";
import { useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const DeleteQuestionForm = (props) => {
    const [text, setText] = useState("Deletion will affect current or in progress submissions.");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();

    const resetForm = () => {
        props.updateDeleteQuestion(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + `/delete-question`

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                uuid: props.uuid,
                index: props.bankUuid && props.bankUuid !== props.uuid ? props.questions[props.questions.findIndex(question => question.uuid === props.bankUuid)].questions.findIndex(question => question.uuid === props.uuid) : props.questions.map((question) => question.uuid).indexOf(props.uuid),
                bankUuid: props.bankUuid
            })
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();

            if (res.status === 200) {
                setText("Question deleted")     
                props.updateDeleteQuestion(false);

                // Remove the question from `to-saved` object
                if (props.uuid in props.questionsToSave) {
                    props.updateQuestionsToSave((prevQuestionsToSave) => {
                        // Create a shallow copy of the previous state
                        const updatedQuestionsToSave = { ...prevQuestionsToSave };
                        
                        // Delete the key corresponding to the UUID
                        delete updatedQuestionsToSave[props.uuid];
                        
                        // Return the updated state
                        return updatedQuestionsToSave;
                    });
                }

                // update
                props.updateQuestions((prevQuestions) =>
                    prevQuestions.map((prevQuestion) => {
                        if (prevQuestion.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {                            
                            const filteredQuestions = prevQuestion.questions.filter(
                                (nestedQuestion) => nestedQuestion.uuid !== props.uuid
                            );
                
                            // Only return the question if it still has valid nested questions
                            return prevQuestion.uuid !== props.uuid ? { ...prevQuestion, questions: filteredQuestions } : null;
                        }
                
                        // Exclude questions with the matching UUID
                        return prevQuestion.uuid !== props.uuid ? { ...prevQuestion } : null;
                    }).filter(Boolean) // Remove null values
                );                

            } else if (res.status === 404) {
                setText("Question not found")
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else if (res.status === 400) {
                setText("Please try again later")
            } else if (res.status === 409) {
                setText("Bank deletion requires zero sub-questions")
            } else {
                setText("Status Code: " + res.status + " Error: " + data.detail)
            }

        } catch (error) {
            setText("Error: " + error.message)
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateDeleteQuestion(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Delete Question
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Delete Question", large : "Delete Question" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default DeleteQuestionForm;