import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const findScore = (results) => {
    try {
        return Number(results.score)
    } catch (error) {
        console.log(error)

        return 0.00
    }
}

const gradeLambda = (question, results, questionType) => {
    let pointsEarned = 0;

    // make sure that useCustomScore is turned off and scoreStatus === APPLIED
    if (question.useCustomScore || (questionType === TypesOfQuestionsEnum.SHORT_RESPONSE && question.scoreStatus !== 'APPLIED')) {
        return question.pointsEarned;
    }

    // for short response
    if (questionType === TypesOfQuestionsEnum.SHORT_RESPONSE) {
        return findScore(results);
    }

    // for lambda
    if (results && typeof results === "object") {
        let testsMap = question?.testsMap;

        if (!testsMap) return pointsEarned;

        Object.keys(results).forEach((resultKey) => {
            if (!(resultKey in testsMap)) return;
            
            if (results[resultKey].passed) {
                pointsEarned += testsMap[resultKey].passPoints;
            } else {
                pointsEarned += testsMap[resultKey].failPoints;
            }
        })
    }

    return pointsEarned;
}

export default gradeLambda