import React from "react";
import Chevron from "./chevron";
import ChevronLeft from "./chevronLeft";

const PDF = (props) => {
    return (
        <>
            {
                props.multiForms

                ?

                <>
                    <div className="form-container">
                        <iframe
                            src={props.pdf}
                            style={{ width: "100%", height: "100%", padding: "0px" }}
                            title="PDF Viewer"
                        />
                        <Chevron numForms={props.numForms} updateFormIndex={props.updateFormIndex} formIndex={props.formIndex}/>
                        <ChevronLeft updateFormIndex={props.updateFormIndex} formIndex={props.formIndex}/>

                    </div>
                </>

                :

                <>
                    <iframe
                        src={props.pdf}
                        className="pdf-container-non-multi"
                        title="PDF Viewer"
                    />
                </>

            }
        </>
    );
};

export default PDF;