import React, { useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import { useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";

const TransferCourseForm = (props) => {
    const [text, setText] = useState("Enter an instructor to transfer ownership");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode } = useParams();
    const [transfer, setTransfer] = useState("");

    function updateTransfer(remove) {
        setTransfer(remove);
    }

    const resetForm = () => {
        props.updateTransfer(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        const url = process.env.REACT_APP_COURSE_API_URL + `/transfer-course`

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                instructorEmail: transfer
            })
        }

        try {
            setRequestLifeCycle(true)
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                window.location.reload();
                props.updateTransfer(false);
            } else if (response.status === 400 || response.status === 404) {
                setText(data.detail);
            } else {
                setText("Status Code: " + response.status + " Error: " + data.detail)
            }

        } catch (error) {
            setText("Error: " + error.message)
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateTransfer(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Transfer Course Ownership
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <CourseInput value={transfer} updateValue={updateTransfer} top={"Instructor to take course ownership"} type={"text"} placeholder={"instructor@example.com"}/>
                            <SaveChanges left="Cancel" right={{ small : "Submit", large : "Submit" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default TransferCourseForm;