import React, { useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import { useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";

const AddInstructorForm = (props) => {
    const [text, setText] = useState("Enter your new instructor information below");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [instructorEmail, setInstructorEmail] = useState("");
    const { courseCode } = useParams();

    function updateInstructorEmail(instructorEmail) {
        setInstructorEmail(instructorEmail)
    }

    const resetForm = () => {
        props.updateCreating(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        if (instructorEmail === "") {
            return;
        }

        const url = process.env.REACT_APP_COURSE_API_URL + "/add-instructor";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                instructorEmail: instructorEmail
            })
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                setText("Evaluation created")
                props.updateCreating(false);
                window.location.reload();
            } else if (res.status === 403) {
                setText("User has student permissions")
            } else if (res.status === 404) {
                setText("User not found")
            } else if (res.status === 409) {
                setText("User is already an instructor in course")
            } else if (res.status === 400) {
                setText(data.detail);
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setText("Status Code: " + res.status + " Error: " + data.detail)
            }

        } catch (error) {
            setText("Error: " + error.message)
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateCreating(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Add An Instructor
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <CourseInput top={"Instructor Email"} type={"text"} required={true} value={instructorEmail} updateValue={updateInstructorEmail} autoComplete={"off"} placeholder={"jdoe@university.edu"}/>
                            <SaveChanges left="Cancel" right={{ small : "Add Instructor", large : "Add Instructor" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default AddInstructorForm;