const compare = (a, b) => {
    if ('decodedEmail' in a && 'decodedEmail' in b) {
        return a.decodedEmail.localeCompare(b.decodedEmail);
    } else if ('decodedEmail' in a) {
        return a.decodedEmail.localeCompare(b.email);
    } else if ('decodedEmail' in b) {
        return a.email.localeCompare(b.decodedEmail);
    } else {
        return a.email.localeCompare(b.email);
    }
}

export default compare;