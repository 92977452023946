import React, { useState, useEffect, useRef } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import NavInfoSubmissions from "./navInfoSubmissions";
import useWindowWidth from "./useWindowWidth";
import DeleteEvaluationForm from "./deleteEvaluationForm";
import NavInfoSubmissionsCollapsed from "./navInfoSubmissionsCollapsed";
import ZipFileViewer from "./zipFileViewer";
import PdfContainerSettings from "./pdfContainerSettings";
import LocationSettingsHover from "./locationSettingsHover";
import CustomProgrammingExam from "./customProgrammingExam";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import DeleteQuestionForm from "./deleteQuestionForm";
import TestsViewer from "./testsViewer";
import InitSortQuestions from "./initSortQuestions";
import fetchWithPreSigned from "./fetchWithPreSigned";
import parseZipFile from "./parseZipFile";
import parseFileStructure from "./parseFileStructureToReturnPaths";
import TypesTestsEnum from "./typesTestsEnum";
import retIgnoredPrefixes from "./retIgnorePrefixes";
import DockerViewer from "./dockerViewer";
import { Mutex } from 'async-mutex';

const EditPage = () => {
    const location = useLocation();
    useScrollToTop();
    const [isEditing, setIsEditing] = useState(true);
    const [addAQuestion, setAddAQuestion] = useState(false);
    const [deleteQuestion, setDeleteQuestion] = useState({
        bool: false,
        bankUuid: "",
        uuid: ""
    });
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode } = useParams();
    const [deleteEvaluation, setDeleteEvaluation] = useState(false);
    const [formIndex, setFormIndex] = useState(0);
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [questionsToSave, setQuestionsToSave] = useState({});
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.CUSTOM_EXAM);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [ignoredPaths, setIgnoredPaths] = useState([]);
    const [contents, setContents] = useState({});
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [requestLifeCycleThree, setRequestLifeCycleThree] = useState(false);
    const [blob, setBlob] = useState(null)
    const [contentsForViewer, setContentsForViewer] = useState(null);
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [currentViewerForTesting, setCurrentViewerForTesting] = useState(TypesTestsEnum.VIEW);
    const [currentFilename, setCurrentFilename] = useState("");
    const [currentFileData, setCurrentFileData] = useState("");
    const [isAllowedToScroll, setIsAllowedToScoll] = useState(false);
    const [testsConfigDictForUpload, setTestsConfigDictForUpload] = useState({})
    const [autograderRequestLifeCycles, setAutograderRequestLifeCycles] =  useState(false)
    const [error, setError] = useState(null);
    const [attempt, setAttempt] = useState(0);
    const [ignoredPrefixes, setIgnoredPrefixes] = useState({})
    const [status, setStatus] = useState(null);
    const [lastStartTime, setLastStartTime] = useState(null);
    const [mutex, setMutex] = useState(new Mutex());
    const [text, setText] = useState("Save");
    const [scrollAmount, setScrollAmount] = useState(0);

    function updateScrollAmount(newAmount) {
        setScrollAmount(newAmount);
    }

    function updateText(newText) {
        setText(newText);
    }

    function updateLastStartTime(newTime) {
        setLastStartTime(newTime);
    }

    function updateTestsConfigDictForUpload(testsConfig) {
        setTestsConfigDictForUpload(testsConfig);
    }
    
    function updateIsAllowedToScoll(isAllowedToScroll) {
        setIsAllowedToScoll(isAllowedToScroll);
    }

    function updateCurrentFilename(filename) {
        setCurrentFilename(filename);
    }

    function updateCurrentFileData(data) {
        setCurrentFileData(data);
    }

    function updateCurrentViewerForTesting(typeOfPage) {
        setCurrentViewerForTesting(typeOfPage)
    }

    function updateError(error) {
        setError(error)
    }

    function updateStatus(status) {
        setStatus(status);
    }

    function updateAutograderRequestLifeCycles(cycle) {
        setAutograderRequestLifeCycles(cycle);
    }

    function updateAttempt(count) {
        setAttempt(count)
    }

    useEffect(() => {
        const fetchTestResults = async () => {
            try {
                setRequestLifeCycleThree(true);
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-tests/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    if (data.OK) {
                        updateTestsConfigDictForUpload(data.testsConfigDict)
                        updateError(null);
                    } else {
                        updateError(data.error);
                        updateTestsConfigDictForUpload({});
                    }
                    updateAttempt(data.attempt)
                    setStatus(data.status);
                    updateLastStartTime(data.lastStartTime);
                } else if (response.status === 404) {
                    // leave error null
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else {
                    updateError("Status Code: " + response.status + " Error: " + data.detail);
                    updateTestsConfigDictForUpload({});
                }
            } catch (error) {
                updateError("Error: " + error.message);
                updateTestsConfigDictForUpload({});
                console.log(error);
            } finally {
                setRequestLifeCycleThree(false);
            }
        }

        fetchTestResults();
    }, [])

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);
        submitForm(theme);
    }

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            }

        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchAssessmentInformation = async () => {
            try {
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-settings/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    setAssessmentInformation(data)
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            }
        }

        fetchAssessmentInformation();
    }, [])

    const fetchCustomExam = async () => {
        try {
            setRequestLifeCycle(true);
            const response = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-custom-exam/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await response.json();
            if (response.status === 200) {
                const questionsInOrder = InitSortQuestions(data.questionsOrder, data.questions);
                setQuestions(questionsInOrder);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        fetchCustomExam();
    }, [])

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycleTwo(true)
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-zip/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }
                    setBlob(blob)
                    const contentsMiddleStage = await parseZipFile(blob);
                    setContentsForViewer(contentsMiddleStage)
                    const contents = parseFileStructure(contentsMiddleStage);
                    setContents(contents);
                    const testsConfig = "tests_config.json" in contents ? JSON.parse(contents["tests_config.json"].content) : {}
                    setTestsConfigDict(testsConfig?.tests.reduce((acc, curr) => {
                        acc[curr.id] = curr;
                        return acc;
                      }, {}));
                    const ignoredPaths = testsConfig?.ignore.reduce((acc, path) => {
                        acc[path] = null;
                        return acc;
                    }, {})

                    setIgnoredPaths(ignoredPaths)
                    setIgnoredPrefixes(retIgnoredPrefixes(ignoredPaths))
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };
        
        if (assessmentInformation.zipFileExists) {
            fetchZip();  
        }        

    }, [assessmentInformation.zipFileExists])

    function updateIsEditing(isEditing) {
        setIsEditing(isEditing);
    }

    function updateAddAQuestion(bool) {
        setAddAQuestion(bool);
    }

    function updateFormIndex(formIndex) {
        if (formIndex < 0) {
            return;
        } else if (formIndex >= assessmentInformation.formIds.length) {
            return;
        } else {
            setFormIndex(formIndex);
        }
    }
        
    const handleDetailsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/settings`);
        }
    }

    const handleStatisticsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/statistics`);
        }
    }

    const handleDashboardClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/`);
        }
    }

    function updateDeleteEvaluation(deleteVal) {
        setDeleteEvaluation(deleteVal);
    }

    function updateCurrentViewer(newViewer) {
        setCurrentViewer(newViewer);
    }

    function updateDeleteQuestion(deleteQuestion) {
        setDeleteQuestion(deleteQuestion);
    }

    function updateQuestions(questions) {
        setQuestions(questions);
    }

    function updateQuestionsToSave(questionsToSave) {
        setQuestionsToSave(questionsToSave);
    }

    function updateContents(contents) {
        setContents(contents);
    }

    const saveAnswersCritical = async (singleQuestion, uuid) => {        
        const release = await mutex.acquire();        

        try {
                const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-questions"
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    questions: singleQuestion ? [questionsToSave[uuid]] : Object.keys(questionsToSave).map(key => questionsToSave[key])
                })
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                if (singleQuestion) {
                    updateQuestionsToSave(() => {
                        return Object.fromEntries(
                            Object.entries(questionsToSave).filter(([key]) => key !== uuid).map(([key, value]) => 
                                [key, value]
                            )
                        );
                    });
                } else {
                    updateQuestionsToSave({});
                }

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                alert("Status Code: " + response.status + " Error: " + data.detail);
            }
        } catch(error) {
            alert("Error: " + error.message);
            console.log(error);
            throw error;

        } finally {
            release();
        }
    };

    function currentViewerToReturn() {
        switch(currentViewer) {
            case TypesOfLocationEnum.PDF: {
                return <PdfContainerSettings assessmentInformation={assessmentInformation} formIndex={formIndex} updateFormIndex={updateFormIndex}/>
            }
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <CustomProgrammingExam updateScrollAmount={updateScrollAmount} scrollAmount={scrollAmount} saveAnswersCritical={saveAnswersCritical} updateQuestions={updateQuestions} assessmentInformation={assessmentInformation} dockerfileAndFunctionExists={assessmentInformation.dockerfileAndFunctionExists} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} updateContents={updateContents} isAllowedToScroll={isAllowedToScroll} updateIsAllowedToScoll={updateIsAllowedToScoll} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} contents={contents} testsConfigDict={testsConfigDict} ignoredPaths={ignoredPaths} requestLifeCycle={requestLifeCycle} questions={questions} questionsToSave={questionsToSave} updateQuestionsToSave={updateQuestionsToSave} isEditing={isEditing} addAQuestion={addAQuestion} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} zipFileExists={assessmentInformation.zipFileExists} useSyntaxHighlighting={assessmentInformation.useSyntaxHighlighting} updateDeleteQuestion={updateDeleteQuestion}/>
            }
            case TypesOfLocationEnum.ZIP: {
                return <ZipFileViewer currentFilename={currentFilename} currentFileData={currentFileData} updateCurrentFilename={updateCurrentFilename} updateCurrentFileData={updateCurrentFileData} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} requestLifeCycle={requestLifeCycleTwo} contents={contentsForViewer} blob={blob}/>
            }
            case TypesOfLocationEnum.TESTS: {
                return <TestsViewer updateLastStartTime={updateLastStartTime} lastStartTime={lastStartTime} updateStatus={updateStatus} status={status} updateAttempt={updateAttempt} attempt={attempt} autograderRequestLifeCycles={autograderRequestLifeCycles} updateAutograderRequestLifeCycles={updateAutograderRequestLifeCycles} error={error} updateError={updateError} updateTestsConfigDictForUpload={updateTestsConfigDictForUpload} testsConfigDictForUpload={testsConfigDictForUpload} currentViewerForTesting={currentViewerForTesting} updateCurrentViewerForTesting={updateCurrentViewerForTesting} testsConfigDict={testsConfigDict} requestLifeCycle={requestLifeCycleThree} questionsFromParent={questions} grading={true} updateCurrentViewer={updateCurrentViewer}/>
            }
            case TypesOfLocationEnum.DOCKER: {
                return <DockerViewer isDarkTheme={isDarkTheme}/>
            }
            default: {
                return <></>
            }
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get("tab")
        const test = queryParams.get("test")

        if (tab) {
            setCurrentViewer(tab)
        }

        if (test) {
            setCurrentViewerForTesting(test)
        }

    }, [location.search]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const currentFormIndex = queryParams.get("formIndex");

        if (currentFormIndex) {
            setFormIndex(Number(currentFormIndex));
        }

    }, [])

    useEffect(() => {        
        if (formIndex >= 0) {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("formIndex", formIndex);
            navigate(`?${queryParams}`)
        }

    }, [formIndex])
    
    useEffect(() => {
        if (text === "Failed") {
            const timer = setTimeout(() => {
                setText("Save");
            }, 2000);
            
            return () => clearTimeout(timer);
        }
    }, [text]);

    return (
        <>
            <Navigation updateText={updateText} text={text} saveAnswersCritical={saveAnswersCritical} updateQuestionsToSave={updateQuestionsToSave} questionsToSave={questionsToSave} updateIsEditing={updateIsEditing} isEditing={isEditing} updateAddAQuestion={updateAddAQuestion} boolSix={currentViewer === TypesOfLocationEnum.CUSTOM_EXAM} info={ <NavInfoSubmissions isProgramming={assessmentInformation.isProgramming} updateDeleteEvaluation={updateDeleteEvaluation} handleDashboardClick={handleDashboardClick} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick} name={assessmentInformation.name} description={"evaluation settings. You may change evaluation settings up to 30 minutes before the scheduled start time."} title={"Manage Settings"}/> } infoCollapsed={ <NavInfoSubmissionsCollapsed handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick}/>}/>
            <div className="courses-container" style={{ position: currentViewer === TypesOfLocationEnum.PDF ? "fixed" : "relative" }}>
                <div className={`single-course-container viewer full ${(width || currentViewer === TypesOfLocationEnum.DOCKER) && currentViewer !== TypesOfLocationEnum.PDF ? "small" : "height"}`}>
                    <div className={`assessment-page-content min ${(width || currentViewer === TypesOfLocationEnum.DOCKER) && currentViewer !== TypesOfLocationEnum.PDF ? "small" : ""}`}>
                        {
                            currentViewerToReturn()
                        }
                    </div>
                </div>
            </div>
            <LocationSettingsHover dockerfileExists={assessmentInformation.dockerfileExists} grading={false} currentViewerForTesting={currentViewerForTesting} pdfExists={assessmentInformation.pdfExists} zipFileExists={assessmentInformation.zipFileExists} updateCurrentViewer={updateCurrentViewer} isProgramming={assessmentInformation.isProgramming}/>
            { deleteEvaluation ? <DeleteEvaluationForm name={assessmentInformation.name} updateDeleteEvaluation={updateDeleteEvaluation} /> : <></> }
            { deleteQuestion.bool ? <DeleteQuestionForm updateQuestions={updateQuestions} updateQuestionsToSave={updateQuestionsToSave} questionsToSave={questionsToSave} uuid={deleteQuestion.uuid} bankUuid={deleteQuestion.bankUuid} updateDeleteQuestion={updateDeleteQuestion} questions={questions}/> : <></> }
        </>
    );
}

export default EditPage;