import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const findStillProcessing = (questionsArray, status, lastStartTime) => {
    // var init
    let mostRecentLastStartTime = null;
    const retValue = {
        questions: false,
        tests: false,
        uuids: []
    }

    // we have programming autograder still running
    const fifteenMinutes = 15 * 60 * 1000;
    let hasBeenFifteenMinutes = !!(((new Date().getTime()) - (new Date(lastStartTime).getTime())) >= fifteenMinutes);

    if (status === 'RUNNING' && !hasBeenFifteenMinutes) {
        retValue.tests = true;
        mostRecentLastStartTime = lastStartTime;
    }

    // exit early if we have tests and questions array is invalid
    if (!questionsArray) {
        return [retValue, mostRecentLastStartTime];
    }
    
    // find if any quesiton is running still and see if its lastStartTime is more recent
    for (let i = 0; i < questionsArray.length; i++) {
        let currentQuestion = questionsArray[i];
        hasBeenFifteenMinutes = !!(((new Date().getTime()) - (new Date(currentQuestion.lastStartTime).getTime())) >= fifteenMinutes);

        if ((currentQuestion.questionType === TypesOfQuestionsEnum.LAMBDA || currentQuestion.questionType === TypesOfQuestionsEnum.SHORT_RESPONSE) && currentQuestion.status === "RUNNING" && !hasBeenFifteenMinutes) {
            retValue.questions = true;
            retValue.uuids.push(currentQuestion.uuid);            
            
            if (new Date(currentQuestion.lastStartTime) > new Date(mostRecentLastStartTime) || !mostRecentLastStartTime) {
                mostRecentLastStartTime = currentQuestion.lastStartTime;
            }
        }
    }

    return [retValue, mostRecentLastStartTime];
}

export default findStillProcessing