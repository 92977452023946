import React, { useEffect, useRef } from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";
import OnlyIInfo from "./onlyIInfo";
import Loading from "./loading";

const GradingPageCustomExamWrapper = ({ scrollAmount, updateScrollAmount, updateAssessmentInformation, status, endedExam, error, testsConfigDict, id, updateId, ignoredPaths, ignoredPrefixes, requestLifeCycleTwo, requestLifeCycle, updatePdfUrls, pdfUrls, isDarkTheme, updateIsDarkTheme, contents, gradedQuestions, updateCurrentViewer }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {            
            ref.current.scrollTo({ top: scrollAmount, behavior: "instant" });
        }

    }, [])

    return (
        <>
            {
                requestLifeCycle ?

                <>
                    <div className="loading-zip-container">
                        <Loading />
                    </div>
                </>

                :

                <>
                    <div ref={ref} className="custom-programming-exam-wrapper" style={{ position: "relative" }} onScroll={(e) => {
                        updateScrollAmount(e.target.scrollTop);                            
                    }}>
                        <MathJaxContext config={mathJaxConfig}>
                            <CustomExamWrapperView updateAssessmentInformation={updateAssessmentInformation} status={status} endedExam={endedExam} error={error} testsConfigDict={testsConfigDict} id={id} updateId={updateId} ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} updatePdfUrls={updatePdfUrls} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={true} updateCurrentViewer={updateCurrentViewer} contents={contents} questions={gradedQuestions} grading={true}/>
                        </MathJaxContext>
                        <OnlyIInfo />
                    </div>
                </>
            }            
        </>
    );
}

export default GradingPageCustomExamWrapper;