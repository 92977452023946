import React from "react";
import LocationSettingsItem from "./locationSettingsItem";
import CodeSlash from "./codeSlash";
import PdfFillDoc from "./pdfFillDoc";
import FilePlay from "./filePlay";
import GradingDoc from "./gradingDoc";
import NewProgrammingExamIcon from "./newProgrammingExamIcon";
import { useLocation } from "react-router-dom";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import Docker from "./docker";
import Error from "./error";

const LocationSettingsHover = ({ errorExists, dockerfileExists, grading, currentViewerForTesting, updateCurrentViewer, isProgramming, pdfExists, zipFileExists }) => {
    const location = useLocation();

    return (
        <>
            <div className="location-settings-hover">
                {
                    errorExists

                    ?

                    <LocationSettingsItem svgOne={<Error />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.ERROR}/>

                    :

                    <>
                    </>
                }
                {
                    !location.pathname.includes('@') && dockerfileExists ?
                    
                    <LocationSettingsItem fetchdata={true} svgOne={<Docker />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.DOCKER}/>

                    :

                    <></>

                }                               
                {
                    pdfExists

                    ?

                    <LocationSettingsItem currentViewerForTesting={currentViewerForTesting} svgOne={<PdfFillDoc />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.PDF}/>

                    :

                    <>
                    </>
                }
                {
                    location.pathname.includes('@') ?
                    
                    <LocationSettingsItem fetchdata={true} svgOne={<GradingDoc />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.SETTINGS}/>

                    :

                    <></>

                }
                <LocationSettingsItem currentViewerForTesting={currentViewerForTesting} svgOne={<NewProgrammingExamIcon />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.CUSTOM_EXAM}/>
                {
                    isProgramming && zipFileExists

                    ?

                    <>
                        <LocationSettingsItem currentViewerForTesting={currentViewerForTesting} svgOne={<CodeSlash />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.ZIP}/>
                        {
                            !grading && (
                                <LocationSettingsItem currentViewerForTesting={currentViewerForTesting} svgOne={<FilePlay />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.TESTS}/>
                            )
                        }                        
                    </>

                    :

                    <>
                    </>
                }                
            </div>
        </>
    );
}

export default LocationSettingsHover;