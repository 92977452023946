const markdownDocsLambda = `
### Lambda Documentation

### Sections

- **General**
- **Java**
- **JavaScript**
- **Python**

### General

Lambda answers have access to all APIs and libraries within the standard language package. However, since scripts will be dynamically generated, 
there are keywords in use by the autograder within the same script that should not be referenced or overwritten. Within all runtimes (Java, JavaScript, and Python), 
inputted code should not declare any variables, classes, etc. with the name \`results\`, as our lambda autograder uses this keyword. The code inputted either through starter code or 
student inputted code will be placed within the same file as the unit tests that you define: importing is not necessary nor beneficial. Note: if a unit test evaluates to true, it will appear green, and if it evaluates to false, it will appear red. All runtimes are executed on Linux. 
For a single evaluation student submission, the execution time limit is ten minutes for each language runtime. For example, if there are five Python questions and three Java questions in one submission, the total execution time for all five
Python questions should be ten minutes or less, and the total execution time for all three Java questions should be ten minutes or less (separate time limits at the langauge level). Adding or removing tests and or changing test-result point values will affect existing submissions.
The maximum size of a single lambda question, including the question string, initial starter code, tests, and other configurations, is ~256KB. If your input exits with a non-zero code,
the standard output (stdout) and the standard error (stderr) will be returned and not any JSON results. If you change the language while your students are taking the evaluation and they are running tests during the evaluation,
you must instruct them to reload their page in order to grade their answer on the updated language you set.

### Java

In Java specifically, the lambda autograder will dynamically create a \`Main.java\` file with a main method. Inputted code will reside
above the main class within the \`Main.java\` file. As a result, your code should NOT contain a main method or main class. In addition to the keyword \`results\`, the \`Main\` class
name is in use and, therefore, should NOT be defined in any inputted code. Additionally, an inputted Java class should not contain a \`public\` access modifier, as the main class is already declared as public
within the same file.

### JavaScript

In Node.js, there is a keyword in use called \`fs\` in addition to \`results\`. Variables or classes should NOT be named \`fs\` or reference \`fs\`. Since JavaScript will be run in Node.js, your code
does not have access to browser APIs that are commonly available for browser-run JavaScript. Similar to Java, inputted code will be defined above the autograder's grading code within the same file.

### Python

In Python, there are no other keywords in use besides \`results\`. Similar to Java and JavaScript, the Python autograder will run your unit tests within the same file as the inputted code.

`;

export default markdownDocsLambda;