import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs, vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Loading from "./loading";
import Tippy from "@tippyjs/react";

const DockerViewer = ({ isDarkTheme }) => {
    const [dockerfile, setDockerfile] = useState("");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();

    useEffect(() => {
            const fetchDockerfile = async () => {
                try {
                    setRequestLifeCycle(true)
                    const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-dockerfile/${courseCode}/${assessmentCode}`, {
                        method: "GET",
                        credentials: "include"
                    });
                    const data = await res.json();
                    if (res.status === 200) {                                        
                        setDockerfile(data.dockerfile);
                    } else if (res.status === 401) {
                        window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setRequestLifeCycle(false);
                }
        }

        fetchDockerfile();

    }, []);

    return (
        <>
            {
                requestLifeCycle ?

                <div className="loading-container" style={{ height: "100vh" }}>
                    <Loading />
                </div>

                :

                <div className="dockerfile-container-center">                    
                    <div className="dockerfile-container-second">
                        <div className="docker-container" style={{ alignItems: "center" }}>
                            <div className="key-value">                                            
                                <svg xmlns="http://www.w3.org/2000/svg" fill="var(--accent-one)" color="var(--accent-one)" width="20" height="20" viewBox="0 0 640 512">
                                    <path color="var(--accent-one)" d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z"/>
                                </svg>
                                Dockerfile
                                <Tippy placement="top" content={"Programming autograder runtime defined below"}>
                                    <div className="info-container dockerfile">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path stroke="currentColor" strokeWidth=".5" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </div>
                                </Tippy>         
                            </div>
                        </div>
                        <SyntaxHighlighter customStyle={{ width: "100%", fontSize: "14px", borderRadius: "5px", padding: "15px", wordBreak: "break-all", backgroundColor: !isDarkTheme ? "var(--gray-one)" : "black", border: !isDarkTheme ? "2px solid var(--gray-four)" : "black" }} language="dockerfile" style={isDarkTheme ? vs2015 : vs}>
                            {dockerfile}
                        </SyntaxHighlighter>                    
                    </div>
                </div>
            }
        </>
    );
}

export default DockerViewer;