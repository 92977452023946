import Tippy from "@tippyjs/react";
import React from "react";

const ReloadButton = ({ size }) => {
    return (
        <>
            <Tippy content="Refresh Results" placement="top">
                <div className="svg-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width={size ? size : 20} height={size ? size : 20} fill="currentColor" viewBox="0 0 16 16">
                        <path strokeWidth={2} d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                        <path strokeWidth={2} d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                    </svg>
                </div>
            </Tippy>
        </>
    );
}

export default ReloadButton;