import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Loading from "./loading";
import Regrade from "./regrade";
import ASingleTestLambdaGrading from "./ASingleTestLambdaGrading";
import FullScreenToggler from "./fullScreenToggler";
import Tippy from "@tippyjs/react";
import keyValueUpdate from "./keyValueUpdate";

const LambdaResponseView = ({ updateAssessmentInformation, status, allowedAttempts, results, useCustomScore, id, updateId, initAnswer, useSyntax, requestLifeCycleTwo, useSyntaxHighlighting, questionNumber, question, language, isDarkTheme, updateIsDarkTheme, points, grading, uuid, pointsEarned, studentAnswer, useAutocompletion, testsMap }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [fullscreen, setFullscreen] = useState(false);

    const updateAField = (key, value) => {
        updateAssessmentInformation((prev) => {            
            return keyValueUpdate(prev, uuid, key, value);
        })
    }

    function updateComponentPointsEarned(newValue) {        
        updateAField("pointsEarned", newValue);
    }
    
    function updateUseCustomScoreFromGrading(bool) {
        updateAField("useCustomScore", bool);
    }

    const handleChange = (value) => {
        if (!grading) {
            setAnswer(value)
        }
    }

    useEffect(() => {
        if (!grading) {
            setAnswer(initAnswer)
        }
    }, [])

    const determineIfWeHaveATestToShow = () => {
        const mapsLength = testsMap && Object.keys(testsMap).length > 0;

        if (results && typeof results === "object" && Object.keys(results).length > 0 && mapsLength) {
            for (const key of Object.keys(results)) {
                if (key in testsMap) {
                    return true;
                }
            }

        } else if (results && typeof results === "string" && mapsLength) {
            return true;
        }

        return false;
    }

    function updateFullscreen(fullscreen) {
        setFullscreen(fullscreen);
    }

    const findMessage = () => {
        if (!allowedAttempts || allowedAttempts === 0) {
            return " Students will be able to test their code submission 0 times.";
        } else if (allowedAttempts && allowedAttempts === 1) {
            return " Students will be able to test their code submission 1 time.";
        } else if (allowedAttempts && allowedAttempts === -1) {
            return " Students will be able to test their code submission an unlimited amount of times.";
        } else if (allowedAttempts && allowedAttempts > 1) {
            return ` Students will be able to test their code submission ${allowedAttempts} times.`;
        }

        return "No attempt information found."
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        {
                            grading && (
                                <Tippy content={uuid === id ? "Deselect Answer" : "Select Answer"} placement="right">
                                    <input type={"checkbox"} className="check-auto" checked={uuid === id} onChange={() => {updateId(uuid === id ? "" : uuid)}} style={{ cursor: "pointer" }}/>
                                </Tippy>
                            )
                        }
                    </div>
                    <div className="pts">
                        {
                            useCustomScore && <Regrade previousScore={pointsEarned} updateComponentPointsEarned={updateComponentPointsEarned} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading} uuid={uuid}/>
                        }                  
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion previousScore={pointsEarned} updateComponentPointsEarned={updateComponentPointsEarned} pointsEarned={pointsEarned} uuid={uuid} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="code-response-area-wrapper">
                    {
                        requestLifeCycleTwo ?
                        
                        <>
                            <div className="loading-zip-container">
                                <Loading />
                            </div>
                        </>

                        :

                        <>
                            <div className={`code-response-area ${fullscreen ? "fullscreen-editor-side-bar" : ""}`} style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)" }}>
                                <div className="code-editor-header" style={{ justifyContent: "space-between" }}>
                                    <div className="location">
                                        Lambda ({language})
                                    </div>
                                    <div className="code-editor-header" style={{ width: "fit-content" }}>
                                        <FullScreenToggler updateFullscreen={updateFullscreen} fullscreen={fullscreen}/>
                                        <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                                    </div>
                                </div>
                                <Editor
                                    language={grading ? language : (useSyntaxHighlighting && useSyntax ? language : "plaintext")}
                                    value={answer}
                                    theme={ isDarkTheme ? "vs-dark" : "vs"}
                                    onChange={(value) => handleChange(value)}
                                    options={{
                                        fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                        fontSize: 14, // Default font size (14px)
                                        tabSize: 8,         // Set tab length to 4 spaces
                                        fontWeight: "500", // Default font weight
                                        suggestOnTriggerCharacters: useAutocompletion,
                                        quickSuggestions: useAutocompletion,  
                                        readOnly: grading,
                                        contextmenu: false
                                    }}
                                />
                                <div className="code-editor-header" />
                            </div>
                        </>
                    }                    
                </div>
                {
                    grading && determineIfWeHaveATestToShow() && (  
                        <div className="true-false-top lambda-results-container">
                            {

                                results && typeof results === "object" ?
                                
                                Object.keys(results).map((id) => {                            

                                    if (id in testsMap) {
                                        const test_result = results[id];
                                        const original_test = testsMap[id];

                                        return <ASingleTestLambdaGrading greyedOut={status === "RUNNING"} key={id} testName={original_test.testName} passPoints={original_test.passPoints} pointsEarned={original_test[test_result.passed ? "passPoints" : "failPoints"]} output={test_result.output} passed={test_result.passed}/>
                                    }
                                })

                                :
                                
                                <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                    {results}
                                </div>
                            }                                
                        </div>                            
                    )
                }     
                {
                    !grading && (
                        <div className="text-response-area">
                            <span className="required" style={{ fontSize: "var(--font-size-one)" }}>
                                *
                            </span>
                            <span className="complete-on-scan">
                                {findMessage()}
                            </span>
                        </div>
                    )
                }           
            </div>
        </>
    );
}

export default LambdaResponseView;