import React, { useState, useEffect } from "react";
import BubbleWithTextView from "./bubbleWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import BubbleWithTextViewGradingPage from "./bubbleWithTextViewGradingPage";
import GradingBoxQuestion from "./gradingBoxQuestion";
import Regrade from "./regrade";
import Tippy from "@tippyjs/react";
import keyValueUpdate from "./keyValueUpdate";

const TrueFalseQuestionView = ({ updateAssessmentInformation, id, updateId, isDarkTheme, useCustomScore, questionNumber, question, points, grading, pointsEarned, studentAnswer, bubbledAnswer, uuid }) => {
    const [bubbled, setBubbled] = useState(-1);
    
    const updateAField = (key, value) => {
        updateAssessmentInformation((prev) => {
            return keyValueUpdate(prev, uuid, key, value);
        })
    }

    function updateComponentPointsEarned(newValue) {        
        updateAField("pointsEarned", newValue);
    }
    
    function updateUseCustomScoreFromGrading(bool) {
        updateAField("useCustomScore", bool);
    }

    function updateBubbled(newBubbled) {
        if (!grading) {
            if (newBubbled === bubbled) {
                setBubbled(-1);
            } else {
                setBubbled(newBubbled);
            }
        }        
    }    

    const handleStateCalculation = (answerState) => {
        // check if answers match or check if they match the possible answerState
        if (studentAnswer === bubbledAnswer && (bubbledAnswer === answerState)) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (studentAnswer !== bubbledAnswer && (studentAnswer === answerState)) {
            return 0;
        }
        // if correct answer and student did not answer it correctly
        else if (studentAnswer !== bubbledAnswer  && (bubbledAnswer === answerState)) {
            return 2
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        {
                            grading && (
                                <Tippy content={uuid === id ? "Deselect Answer" : "Select Answer"} placement="right">
                                    <input type={"checkbox"} className="check-auto" checked={uuid === id} onChange={() => {updateId(uuid === id ? "" : uuid)}} style={{ cursor: "pointer" }}/>
                                </Tippy>
                            )
                        }
                    </div>
                    <div className="pts">
                        {
                            useCustomScore && <Regrade previousScore={pointsEarned} updateComponentPointsEarned={updateComponentPointsEarned} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading} uuid={uuid}/>
                        }
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion previousScore={pointsEarned} updateComponentPointsEarned={updateComponentPointsEarned} pointsEarned={pointsEarned} uuid={uuid} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="true-false-answer-block">
                    {
                        grading

                        ?

                        <>
                            <BubbleWithTextViewGradingPage text={"True"} state={handleStateCalculation(1)}/>
                            <BubbleWithTextViewGradingPage text={"False"} state={handleStateCalculation(0)}/>
                        </>

                        :

                        <>
                            <BubbleWithTextView text={"True"} bubbled={bubbled === 1} updateBubbled={updateBubbled}/>
                            <BubbleWithTextView text={"False"} bubbled={bubbled === 0} updateBubbled={updateBubbled}/>
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default TrueFalseQuestionView;