import JSZip from 'jszip';

async function validateZipFile(zipFile) {
    if (!zipFile || !zipFile.type.includes("zip")) {
        return [false, "Invalid file type. Please upload a ZIP file."];
        }

    // Check if the file size is less than or equal to 5MB
    if (zipFile.size > 5 * 1024 * 1024) {
    return [false, "Zip file size exceeds 5MB"];
    }

    const zip = new JSZip();

    // Load the zip file
    let loadedZip = null;

    try {    
        loadedZip = await zip.loadAsync(zipFile);
    } catch (error) {
        return [false, "Invalid ZIP file. Error: " + error.message];
    }
    
    if (!loadedZip) {
        return [false, "Invalid ZIP file. The file may be corrupted or not a ZIP archive."];
    }
    

    // Check for setup.sh at the root
    if (!loadedZip.files['setup.sh']) {
        return [false, "Missing setup.sh at root"];
    }

    // Check for tests_config.json at the root
    if (!loadedZip.files['tests_config.json']) {
        return [false, "Missing tests_config.json at root"];
    }

    // Load and parse tests_config.json
    const testsConfig = await loadedZip.file('tests_config.json').async('string');
    let parsedConfig;

    try {
        parsedConfig = JSON.parse(testsConfig);
    } catch (error) {
        return [false, "tests_config.json is not valid JSON"];
    }

    // Check for 'ignore' and 'tests' arrays in tests_config.json
    if (!Array.isArray(parsedConfig.ignore)) {
        return [false, "Missing or invalid 'ignore' array in tests_config.json"];
    }

    // Verify each item in 'ignore' is a string
    const invalidIgnoreItem = parsedConfig.ignore.find(item => typeof item !== 'string');
    if (invalidIgnoreItem !== undefined) {
        return [false, "All items in 'ignore' must be strings"];
    }

    if (!Array.isArray(parsedConfig.tests)) {
        return [false, "Missing or invalid 'tests' array in tests_config.json"];
    }

    // Verify each test in 'tests' contains required fields and valid types
    for (const test of parsedConfig.tests) {
        if (
            test === null ||
            test === undefined ||
            !test.hasOwnProperty('name') ||
            typeof test.name !== 'string' ||
            !test.hasOwnProperty('totalPoints') ||
            typeof test.totalPoints !== 'number' ||
            !test.hasOwnProperty('id') ||
            typeof test.id !== 'string'
        ) {
            return [false, "Each test must contain 'name' (string), 'totalPoints' (float), and 'id' (string)"];
        }
    }

    // Verify all test IDs are unique
    const ids = parsedConfig.tests.map(test => test.id);
    const uniqueIds = new Set(ids);
    if (uniqueIds.size !== ids.length) {
        return [false, "All test IDs must be unique within the 'tests' array"];
    }

    // All requirements are met
    return [true, "All requirements met"];
}

export default validateZipFile;