const markdownDocs = `
### Programming Autograder Documentation

### Sections

- **Overview**
- **Dockerfile**
- **Zip File Requirements**
- **JSON File Requirements**
- **Example**

### Overview

This document provides an outline of the GoValuate Programming Autograder, including required files, best practices, and an explanation of the autograder run-time environment.

### Dockerfile

In order to use the GoValuate Programming Autograder, you must successfully upload a Dockerfile, 
which will be the environment where student zip file code submissions are ran. You are free to install any and all packages for your autograder
with a few restrictions. If you are unfamiliar with Docker, please read the [Docker Documentation](https://docs.docker.com/).

##### - Starter Dockerfile

\`\`\`dockerfile
# DO NOT EDIT THIS LINE
FROM public.ecr.aws/lambda/python:3.12

# DO NOT EDIT THIS LINE
COPY . \${LAMBDA_TASK_ROOT}

# DO NOT EDIT THIS LINE
WORKDIR \${LAMBDA_TASK_ROOT}

# DO NOT EDIT THIS LINE
RUN pip install -r requirements.txt

# DO NOT EDIT THIS LINE
RUN dnf install -y bash

# =================================================

# <------ insert package installations here ------>

# =================================================

# DO NOT EDIT THIS LINE
CMD ["lambda_function.lambda_handler"]
\`\`\`

##### - Restrictions

Your Dockerfile must include all lines in the starter Dockerfile and should start from it. 
You may NOT change any of the lines labeled with \`# DO NOT EDIT THIS LINE\`.
Therefore, you may NOT change the base image (FROM), the base command (CMD), the working directory (WORKDIR), 
the bash installation, or the requirements.txt installation. Since you will be uploading only the Dockerfile, you may not add any custom COPY commands.
This initial configuration is required to make the autograder function with our infrastructure. Furthermore,
the Dockerfile, once built, may not exceed 9GB, and ideally, it should be much smaller than this. 
Your Docker build has a one hour time limit to finish, and ideally, it should be much shorter than this.

##### - Installations

Any packages your zip file will need should be installed via Docker \`RUN\` commands within the designated location in the starter Dockerfile.
Since the autograder base image uses Amazon Linux 2023, you should use \`dnf install -y\` to install any dependencies at the system level. The \`-y\`
flag is crucial because it automatically confirms the installation of packages, preventing any hanging caused by installation prompts. 
Furthermore, you can view the full list of packages available for download here: 
[Amazon Linux 2023 Package List](https://docs.aws.amazon.com/linux/al2023/release-notes/all-packages-AL2023.6.html).
We highly recommend attempting to build your Dockerfile on your local machine before uploading one to GoValuate. 
This will help you identify and fix any bugs before uploading it to GoValuate, preventing delays. 
Lastly, packages will be installed, and the code will be run on the ARM64 architecture.

##### - Dockerfile Uploads

A successful Dockerfile build is defined as follows: status of \`Finished\` 
and a blank \`Errors\` field. Even with this build state, we recommend 
running the autograder to be sure that the build was completely successful. 
You may view build progress in the settings tab of an evaluation.
Any case that does not satisfy these constraints is classified as 
an unsuccessful build. You or your students may not use the programming
autograder until you have had a successful build. If you have had a 
successful build then initiate another build that is unsuccessful, the programming autograder
will still be able to be used; however, the build in use will be the one last classified as successful.

### Zip File Directory Structure

The following directory tree illustrates the requirements of an uploaded zip file for the autograder. The location of the \`tests_config.json\`
file must remain the same after your scripts have finished execution:

\`\`\`plaintext
root/
├── setup.sh                                       (required)
├── tests_config.json                              (required)
├
├── <files for running tests (called by setup.sh)> (optional)
├── <files for question mapping>                   (optional)
\`\`\`

##### - Restrictions
 
There is a 10 minute execution time limit, 10 Gigabytes of ephemeral storage, 
and 3 Gigabytes of RAM allocated for each short-lived invocation of the autograder. However, an uploaded starter 
zip file cannot exceed 5MB in size, and the final zip file (after a student has 
entered answers) may not exceed 6MB. 

##### - File: setup.sh

The \`setup.sh\` file is the entry point of your scripts
that will be called upon fetch of your custom GoValuate container image. Your \`setup.sh\` file 
should call your test files. You may 
use \`dnf install -y\` in your \`setup.sh\` file to install any dependencies
that are required to be installed at runtime, but this is not encouraged and may result in errors. If you must install some dependencies at runtime, 
you must install them to the \`/tmp\` directory. The autograder environment is completely read-only except for \`/tmp\` (the only writable location in the environment).
All of your files will be stored at \`/tmp/autograder/\`. If you directly, or any package indirectly, attempts to write anything to a location other than \`/tmp\`, you will receive an error.
Your \`setup.sh\` file should call your test files. When your autograder invocation spins up for a submission, the \`/tmp\` directory will be completely empty (minus the zip file code). After each
invocation, the \`/tmp\` will be cleared: data will not persist between invocations.

##### - File: tests_config.json

The \`tests_config.json\` file is the destination for 
the results of your tests, which should be within the initial zip file uploaded before
your evaluation is taken for question-to-file mapping (see more in next section). The \`tests_config.json\` file cannot 
exceed ~256KB in size after the autograder finishes. The \`tests_config.json\` file and 
\`setup.sh\` file should always be at the root of the zip file.

##### - Exit Code of setup.sh

If your \`setup.sh\` exits with a non-zero code,
the standard output (stdout) and the standard error (stderr) will be returned and not any JSON results. 
Points earned for each question will not be reset to zero 
when a student's submission results in an error from your 
setup.sh. Therefore, they will keep the points associated with 
the last run of the autogarder that resulted in
a correct JSON output and successful \`setup.sh\` exit.

##### - Suggestions

If your \`setup.sh\` exits with a non-zero code,
the output can be quite verbose depending on your grading setup. 
We recommend doing some pattern matching to only output necessary messages to the student
when their submission results in an error (and hide unnecessary output like build logs or echo messages). 
A great method for this is clearing stdout and stderror, using the \`grep\` command, and matching lines with expected error strings.

### tests_config.json

Initial \`tests_config.json\` file within the uploaded zip file (note: when a student starts an attempt (including continuous type), 
the zip file is forked (copied completely), and any subsequent changes to the evaluation zip file will not affect any existing student 
zip file submissions, finished or in progress. However, updating the Dockerfile will affect existing submissions):

\`\`\`json
{
  "ignore": [                       (required: may have length zero)
    <string>,
    <string>,
    ...,
    <string>
  ],
  "tests": [                        (required: may have length zero)
    {
        "name": <string>,           (required)
        "totalPoints": <float>,     (required)
        "id": <string>              (required)
    },
    {
        "name": <string>,
        "totalPoints": <float>,
        "id": <string>
    },    
    ...,
    {
        "name": <string>,
        "totalPoints": <float>,
        "id": <string>,
    }
  ]
}
\`\`\`

Final tests_config.json file returned after \`setup.sh\` finishes execution:

\`\`\`json
{
  "ignore": [
    <string>,
    <string>,
    ...,
    <string>
  ],
  "tests": [
    {
        "name": <string>,        
        "pointsEarned": <float>, (optional: will default to 0.0)
        "totalPoints": <float>,  
        "output": <string>,      (optional)
        "id": <string>           
    },
    {
        "name": <string>,
        "pointsEarned": <float>,
        "totalPoints": <float>,
        "output": <string>,
        "id": <string>
    },    
    ...,
    {
        "name": <string>,
        "pointsEarned": <float>,
        "totalPoints": <float>,
        "output": <string>,
        "id": <string>,
    }
  ]
}
\`\`\`

##### - Ignore Array

The ignore array will store strings of full paths to files
relative to the zip file root. Use forward slashes to deliminate 
file hierarchy. The path should NOT start with a forward slash or a . (e.g. ./) but rather the name of
the root file or directory at the beginning of the desired path. Each string will have the effect 
of popping its specified file from student viewing and access.
A question cannot map to an ignored file. You may ignore an entire directory 
(its dirname and all of its contents) with the wildcard character \`*\` 
(e.g. \`<dir_1_at_root>/<sub_dir_inside_dir_1>/*\` will only ignore the subdirectory not its parent directory).

#### - Mapping

Each \`id\` should be universally unique within the tests array. 
The ignore and tests arrays may have length zero but are required. Finally, 
the initial JSON version is used for question mapping, and the final JSON version is 
used for displaying results to the student or instructor, as well as grading and assigning points to questions.

### Example

Zip File Directory Structure:

\`\`\`plaintext
root/
├── setup.sh             (will call test_controller.py)
├── tests_config.json
├
├── test_controller.py
├── question_one.py      (mapping file for user input)
\`\`\`

Initial \`tests_config.json\` file within the uploaded zip file:

\`\`\`json
{
  "ignore": [
    "setup.sh",
    "test_controller.py",
    "tests_config.json"
  ],
  "tests": [
    {
        "name": "Test 1",
        "totalPoints": 2.0,
        "id": "0"
    },
    {
        "name": "Test 2",
        "totalPoints": 1.0,
        "id": "1"
    },
    {
        "name": "Test 3",
        "totalPoints": 3.0,
        "id": "2"
    }
  ]
}
\`\`\`

The file-location and test-selection mapping will take place during the 
evaluation build process on GoValuate within the edit evaluation section 
(after a student fork, changing question-to-file locations will affect existing submissions but changing test-selection mappings will not). 
Multiple questions can map to the same file path within the zip file.
You may include starter code within your files that will be available 
to students upon the start of the evaluation. When a question maps to a file
within the zip file, this will save user input to that file location, acting as a pointer. Once the student submits
their evaluation, the complete zip file, which contains user code input, will be autograded according to the 
tests you define within your zip file testing code. We strongly suggest using reflection when writing your test files and using separate Git branches for solutions
and initial starter code (if you choose to use Git).


`;

export default markdownDocs;