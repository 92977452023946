import React, { useState, useEffect } from "react";
import PDFIcon from "./pdfIcon";
import PDF from "./pdf";
import useWindowWidth from "./useWindowWidth";
import Loading from "./loading";
import { useParams } from "react-router-dom";
import fetchWithPreSigned from "./fetchWithPreSigned";

const PdfContainerSettings = ({ assessmentInformation, formIndex, updateFormIndex }) => {
    const [pdf, setPdf] = useState("");
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    useEffect(() => {
        if (assessmentInformation.formIds && assessmentInformation.formIds[formIndex] && assessmentInformation.formIds.length > 0) {
            const fetchPdf = async () => {
                try {
                    setRequestLifeCycle(true);
                    const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-pdf/${courseCode}/${assessmentCode}/${assessmentInformation.formIds[formIndex]}`, {
                        method: "GET",
                        credentials: "include"
                    });
                    const data = await res.json();
                    if (res.status === 200) {
                        const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                        if (status !== 200) {
                            return;
                        }

                        const pdfUrl = URL.createObjectURL(blob); // Directly create object URL
                        setPdf(pdfUrl);
                    } else if (res.status === 401) {
                        window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setRequestLifeCycle(false);
                }
            };
    
            fetchPdf();
        }

        return () => {
            if (pdf) {
                URL.revokeObjectURL(pdf)
            }
        }

    }, [assessmentInformation.formIds, formIndex])

    return (
        <>
            <div className={ `pdf-content-container` }>
                {
                    requestLifeCycle

                    ?

                    <div className="loading-zip-container">
                        <Loading />
                    </div>

                    :

                    <>
                        {
                            pdf === "" 
        
                            ?
        
                            <div className="loading-zip-container">
                                <PDFIcon />                                
                            </div>
        
                            :
        
                            <>
                                <PDF pdf={pdf} multiForms={true} numForms={assessmentInformation.formIds.length} updateFormIndex={updateFormIndex} formIndex={formIndex}/>
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
}

export default PdfContainerSettings;