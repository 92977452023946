import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import { useParams, useNavigate } from "react-router-dom"; // need for api call later
import useScrollToTop from "./useScrollToTop";
import NavInfoCourse from "./navInfoCourse";
import useWindowWidth from "./useWindowWidth";
import AddInstructorForm from "./addInstructorForm";
import Student from "./student";
import EditCourseForm from "./editCourseForm";
import RemoveInstructorForm from "./removeInstructorForm";
import ChangeExtraTimeForm from "./changeExtraTimeForm";
import RemoveStudentForm from "./removeStudentForm";
import Loading from "./loading";
import DuplicateForm from "./duplicateForm";
import DeleteCourseForm from "./deleteCourseForm";
import NavInfoCourseCollapsed from "./navInfoCourseCollapsed";
import Search from "./search";
import PersonFill from "./personFill";
import DownArrow from "./downArrow";
import compare from "./compare";
import TransferCourseForm from "./transferCourseForm";

const CourseRoster = () => {
    useScrollToTop();
    const navigate = useNavigate();
    const { courseCode } = useParams(); // need for api call later                                         // need to make api request for email list
    const [removeStudent, setRemoveStudent] = useState(false);
    const [addTime, setAddTime] = useState(false);
    const [creating, setCreating] = useState(false);
    const [deleteCourse, setDeleteCourse] = useState(false);
    const [remove, setRemove] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [roster, setRoster] = useState({});
    const [course, setCourse] = useState({});
    const [editCourse, setEditCourse] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [currentStudent, setCurrentStudent] = useState({
        name : "",
        email : "",
        extraTime : false,
        decodedEmail: ""
    });
    const [instructor, setInstructor] = useState({
        name : "",
        email : "",
    });
    const [nameFlip, setNameFlip] = useState(false);
    const [emailFlip, setEmailFlip] = useState(false);
    const width = useWindowWidth(800);
    const widthFour = useWindowWidth(685);
    const [transfer, setTransfer] = useState(false);

    function updateTransfer(newBool) {
        setTransfer(newBool);
    }

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_COURSE_API_URL + `/get-roster/${courseCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    setRoster(data)
                    setStudentsArr(data.students);
                    setInstructorsArr(data.instructors);

                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        const fetchCourseInfo = async () => {
            try {
                const res = await fetch( process.env.REACT_APP_COURSE_API_URL + `/get-course/${courseCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    setCourse(data);
                    
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else if (res.status === 404 || res.status === 403) {
                    navigate("/");
                }
        
            } catch (error) {
                console.log(error);
            }
        }

        fetchCourseInfo();
        fetchCourse();
    }, [])


    const [studentsArr, setStudentsArr] = useState([]);
    const [instructorsArr, setInstructorsArr] = useState([]);

    function updateCreating(bool) {
        setCreating(bool);
    }

    function updateRemove(bool) {
        setRemove(bool);
    }

    function updateInstructor(instructor, email) {
        setInstructor({
            name: instructor,
            email: email
        });
    }

    function updateStudent (name, email, decodedEmail) {
        setCurrentStudent({
            name : name,
            email : email,
            decodedEmail: decodedEmail
        })
    }

    function updateRemoveStudent(bool) {
        setRemoveStudent(bool)
    }

    function updateAddTime(bool) {
        setAddTime(bool);
    }

    function updateDuplicate(bool) {
        setDuplicate(bool);
    }

    function updateEditCourse(bool) {
        setEditCourse(bool)
    }

    function updateDeleteCourse(bool) {
        setDeleteCourse(bool);
    }

    function updateSearchName(e) {
        setSearchName(e.target.value);
    }

    const sortName = () => {
        let sortOrder = null;

        for (let i = 0; i < studentsArr?.length; i++) {
            let l = i - 1;

            if (i > 0 && studentsArr[l].name.localeCompare(studentsArr[i].name) > 0) {
                sortOrder = true;
                break;
            }
        }
    
        const sortedArr = [...studentsArr].sort((a, b) => {
            if (sortOrder) {
                return a.name.localeCompare(b.name);  // Correctly compare strings
            } else {
                return b.name.localeCompare(a.name);  // Correctly compare strings in reverse order
            }
        });
    
        setStudentsArr(sortedArr);

        setNameFlip(prev => !prev);
        setEmailFlip(false);
    };    
    
    const sortEmail = () => {
        let sortOrder = null;

        for (let i = 0; i < studentsArr?.length; i++) {
            let l = i - 1;

            if (i > 0 && compare(studentsArr[l], studentsArr[i]) > 0) {
                sortOrder = true;
                break;
            }
        }
    
        const sortedArr = [...studentsArr].sort((a, b) => {
            if (sortOrder) {
                return compare(a,b)  // Correctly compare strings
            } else {
                return compare(b,a)  // Correctly compare strings in reverse order
            }
        });
    
        setStudentsArr(sortedArr);

        setEmailFlip(prev => !prev);
        setNameFlip(false);
    };
    

    return (
        <>
            <Navigation boolThree={true} boolTwo={false} bool={false} info={<NavInfoCourse showTransfer={course.showTransfer} updateTransfer={updateTransfer} updateCourse={updateEditCourse} updateDuplicate={updateDuplicate} updateRemove={updateRemove} updateInstructor={updateInstructor} name={roster.name} description={roster.description} instructors={instructorsArr} updateDeleteCourse={updateDeleteCourse}/>} infoCollapsed={<NavInfoCourseCollapsed />}/>
            <div className="courses-container">
                <div className="header">
                    <div className="single-course-container">
                        <div className="your-course">
                            <div className="course-metadata" style={{ flexDirection: width ? "column" : "row", alignItems:  width ? "flex-start" : "center" }}> 
                                {
                                    roster && Object.keys(roster).length > 0

                                    ?

                                    <>
                                        <div className={`course-name ${width ? (widthFour ? "overflow-name-small-small" : "overflow-name-small") : "overflow-name"}`}>
                                            { roster.name }
                                        </div>
                                        { width ? <></> : <div className="bar"></div> }
                                        <div className="font two three overflow-code flex-code">
                                            <PersonFill /> { studentsArr.length + " Students"}
                                        </div>
                                        <div className={ widthFour ? "search-holder-bottom" : "search-holder"} style={{width: "180px"}}>
                                            <Search searchName={searchName} updateSearchName={updateSearchName}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>

                                }
                            </div>
                            <div className="course-metadata-2"> 
                                <div className="roster-meta">
                                    {
                                        widthFour

                                        ?

                                        <>
                                            <div className="roster-meta-title" style={{ width: "50%", marginRight: "20px" }}>
                                                <div className="status-container" onClick={ sortName } style={{ userSelect: "none" }}>
                                                    <DownArrow flip={nameFlip}/>
                                                    Name
                                                </div>
                                            </div>
                                            <div className="roster-meta-title" style={{ width: "50%", justifyContent: "flex-end" }}>
                                                <div className="status-container" onClick={ sortEmail } style={{ userSelect: "none" }}>
                                                    <DownArrow flip={emailFlip}/>
                                                    Email
                                                </div>
                                            </div>
                                        </>

                                        :
                                        
                                        <>
                                            <div className="roster-meta-title" style={{ marginRight: "20px", width: "35%" }}>
                                                <div className="status-container" onClick={ sortName } style={{ userSelect: "none" }}>
                                                    <DownArrow flip={nameFlip}/>
                                                    Name
                                                </div>
                                            </div>
                                            <div className="roster-meta-title" style={{ userSelect: "none", width: "30%" }}>
                                                <div className="status-container" onClick={ sortEmail } style={{ userSelect: "none" }}>
                                                    <DownArrow flip={emailFlip}/>
                                                    Email
                                                </div>
                                            </div>
                                            <div className="roster-meta-title" style={{ userSelect: "none", width: "20%" }}>
                                                <div className="roster-meta-title three" style={{ width: "fit-content" }}>
                                                    SID
                                                </div>
                                            </div>
                                            <div className="roster-meta-title three" style={{ userSelect: "none" }}>
                                                <div className="roster-meta-title three">                                                
                                                    Extension
                                                </div>
                                            </div>
                                        </>
                                        
                                    
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="exams-container">
                            {
                                requestLifeCycle

                                ?

                                <>
                                    <div className="loading-container" style={{ height: "200px" }}>
                                        <Loading />
                                    </div>  
                                </>

                                :

                                <>
                                    {
                                        studentsArr && studentsArr.length > 0

                                        ?

                                        <>  
                                            {
                                                studentsArr.map((student) => {
                                                    if (student.name.toLowerCase().includes(searchName.toLowerCase())) {
                                                        return <Student decodedEmail={student.decodedEmail} studentId={student.studentId} updateAddTime={updateAddTime} updateRemoveStudent={updateRemoveStudent} updateStudent={updateStudent} key={student.email} {...student} />
                                                    }
                                                })
                                            }
                                        </>

                                        :

                                        <>
                                            <div className="no-assignments-container">
                                                <div className="no-assignments"> 
                                                   There are no enrolled students in this course
                                                    <div className="save-changes-2" onClick={() => { navigate(`/${courseCode}`); }}>
                                                        Back to Dashboard
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>

                            }
                        </div>
                    </div>
                </div>
            </div>
            { creating ? <AddInstructorForm updateCreating={updateCreating}/> : <></> }
            { deleteCourse ? <DeleteCourseForm name={course.name} updateDeleteCourse={updateDeleteCourse}/> : <></>} 
            { remove ? <RemoveInstructorForm email={instructor.email} name={instructor.name} updateRemove={updateRemove}/> : <></> }
            { addTime ? <ChangeExtraTimeForm assessments={course.assessments} decodedEmail={currentStudent.decodedEmail} students={roster.students} name={currentStudent.name} email={currentStudent.email} updateAddTime={updateAddTime}/> : <></> }
            { removeStudent ? <RemoveStudentForm assessments={roster.assessments} decodedEmail={currentStudent.decodedEmail} email={currentStudent.email} name={currentStudent.name} updateRemoveStudent={updateRemoveStudent}/> : <></> }
            { duplicate ? <DuplicateForm name={course.name} year={course.year} term={course.term} description={course.description} assessmentObjects={course.assessments} updateDuplicate={updateDuplicate}/> : <></> }
            { editCourse ? <EditCourseForm updateEditCourse={updateEditCourse} name={course.name} year={course.year} term={course.term} description={course.description} color={course.color}/> : <></> }
            { transfer ? <TransferCourseForm updateTransfer={updateTransfer} /> : <></> }
        </>
    );
}

export default CourseRoster;