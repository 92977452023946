import React, { useState, useEffect } from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import WhiteLoading from "./whiteLoading";
import ASingleTestGrading from "./aSingleTestGrading";
import SelectZipFile from "./selectZipFile";
import { useParams } from "react-router-dom";
import uploadFileToS3 from "./uploadFileToS3";
import validateZipFile from "./validateZipFile";
import Tippy from "@tippyjs/react";
import LoadingSpinner from "./loadingSpinner";
import ShowError from  "./showError";
import ViewDocs from "./viewDocs";

const UploadAndViewTests = ({ updateLastStartTime, lastStartTime, updateTestsConfigDictForUpload, updateStatus, status, updateAttempt, attempt, autograderRequestLifeCycles, updateAutograderRequestLifeCycles, error, updateError, testsConfigDictForUpload, questions, updateCurrentViewer }) => {
    const [inputZipFile, setInputZipFile] = useState("");
    const [text, setText] = useState("");
    const { courseCode, assessmentCode } = useParams();  
    const [showResults, setShowResults] = useState(false);
    const [flip, setFlip] = useState(false);

    const fifteenMinutes = 15 * 60 * 1000;
    const hasBeenFifteenMinutes = !!(((new Date().getTime()) - (new Date(lastStartTime).getTime())) >= fifteenMinutes);

    const updateZipFile = async (file) => {
        const [isValid, message] = await validateZipFile(file);
        
        if (!isValid) {
            setText(message);
            return;
        }

        setInputZipFile(file)
    }

    const uploadZipBeforeAutograde = async () => {
        try {
            const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-url-test-zip/${courseCode}/${assessmentCode}`, { method: "GET", credentials: "include" })
            const data = await response.json()

            if (response.status === 200 && data.url) {                
                const status = await uploadFileToS3(inputZipFile, data.url, "application/zip");

                if (status !== 200) {
                    updateError("Zip failed to upload");
                    return 400
                }

                return 200

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_SUBMISSION_API_URL;
            } else {
                updateError("Status Code: " + response.status + " Error: " + data.detail)
                return 400
            }
        } catch (error) {
            console.log(error)
            updateError("Error: " + error.message)            
            return 400
        }
    }

    useEffect(() => {
        const uploadAndTestZipFile = async () => {
            if (autograderRequestLifeCycles) {
                return;
            }
            
            // max size
            const maxSizeBytes = 5 * 1024 * 1024;

            // form validation
            if (inputZipFile.size > maxSizeBytes) {
                updateError("Zip File exceeds 5MB.")
                return;
            }

            try {
                updateAutograderRequestLifeCycles(true)
                const statusCode = await uploadZipBeforeAutograde();

                if (statusCode !== 200) {
                    return;
                }

                if (!inputZipFile) {
                    return;             
                }

                const url = process.env.REACT_APP_EVALUATION_API_URL + `/test-programming/${courseCode}/${assessmentCode}`
                const urlOptions = {
                    method: "POST",
                    credentials: "include"
                }

                const response = await fetch(url, urlOptions);
                const data = await response.json();
                if (response.status === 200) {
                    updateAttempt((attempt !== null && attempt !== undefined) ? (attempt + 1) : 1);
                    updateStatus('RUNNING');
                    updateLastStartTime(new Date());
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                } else {
                    updateError("Status Code: " + response.status + " Error: " + data.detail);
                }

            } catch (error) {               
                updateError("Error: " + error.message);
                console.log(error);
            } finally {
                updateAutograderRequestLifeCycles(false);
                setShowResults(true);
            }
        }

        if (inputZipFile) {
            uploadAndTestZipFile();
        }

    }, [inputZipFile])

    useEffect(() => {
        setText(questions && questions.length > 0 ? "Upload a test zip file below" : "No questions to test at this time");
    }, [questions])

    const findMessage = () => {
        if (status === 'RUNNING' && !hasBeenFifteenMinutes) {
            return (
                <div className="attempt-processing-container">
                    Test New Zip (Attempt {attempt} is processing) <LoadingSpinner white={true}/>
                </div>
            );
        }
    
        return (
            <>
                Test New Zip ({`Attempt ${attempt}`})
            </>
        );
    }

    const fetchResults = async () => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + `/get-tests/${courseCode}/${assessmentCode}?no_questions=${true}`
            const urlOptions = {
                method: "GET",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                if (data.status !== 'RUNNING') {
                    if (data.OK) {
                        updateTestsConfigDictForUpload(data.testsConfigDict)                        
                        updateError(null);
                    } else {
                        updateError(data.error);
                        updateTestsConfigDictForUpload({});
                    }
                    updateAttempt(data.attempt)
                    updateStatus(data.status);
                }
                updateLastStartTime(data.lastStartTime);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                updateTestsConfigDictForUpload({});
                updateError("Status Code: " + response.status + " Error: " + data.detail);
            }

        } catch (error) {
            console.log(error);
            updateTestsConfigDictForUpload({});
            updateError("Error: " + error.message);
        } finally {
            setShowResults(true);
        }
    }

    useEffect(() => {        
        const interval = setInterval(() => {
            if (status === 'RUNNING' && !hasBeenFifteenMinutes && showResults) {
                fetchResults();
                setFlip(prev => !prev);
            }            
            
        }, 3000);
    
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };

    }, [flip, status, lastStartTime, showResults]);

    const willRenderNothing = (questions, testsConfig) => {
        return !questions.some(question =>
            question.tests.some(id => id in testsConfig)
        );
    };

    const blank = "The results have zero test IDs that match programming questions with assigned test IDs.";

    if (willRenderNothing(questions, testsConfigDictForUpload)) {
        if (!error && status !== "RUNNING" && attempt > 0) {
            updateError(blank);        
        }
    } else if (error === blank) {
        // there are results so clear the error
        updateError(null);
    }

    return (
        <>
            {
                autograderRequestLifeCycles

                ?

                <div className="loading-zip-container">
                    <WhiteLoading />
                </div>

                :

                <>
                    {
                        showResults

                        ?

                        <>
                            <div className="question-tests-container">
                                <div className="clear-container" style={{ columnGap: "10px" }}>                                   
                                    <div className="top-right-item" onClick={() => {
                                        setShowResults(false);
                                    }} style={{ paddingTop: "10px", paddingBottom: "10px", height: "fit-content", width: "auto", paddingLeft: "30px", paddingRight: "30px" }}>
                                        {findMessage()}
                                    </div>
                                </div>
                                {                                   
                                    error ? 

                                        <div className="stop-overflow-compilation" style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                            <ShowError error={error} full={false}/>
                                        </div>

                                    :

                                    questions.map((question) => (
                                        question.tests.map((id) => {
                                            if (id in testsConfigDictForUpload) {
                                                return <ASingleTestGrading greyedOut={status === "RUNNING"} instructorPage={true} key={id} {...testsConfigDictForUpload[id]} index={question.index}/>
                                            }
                                        })
                                    ))
                                }
                            </div>
                        </>

                        :

                        <>
                            <div className="no-questions">
                                <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                                    <div className="no-assignments">
                                        { text }
                                        {
                                            questions && questions.length > 0 ?

                                            <div className="input-wrapper-test-zip" style={{ position: "relative" }}>
                                                <ViewDocs path={'/autograder-docs'}/>
                                                <div style={{ height: "27.5px" }}>
                                                    <SelectZipFile upload={true} star={false} value={inputZipFile} updateValue={updateZipFile} required={true}/>
                                                </div>
                                                <Tippy placement="top" content="View results for tests assigned to programming questions" theme="custom-tooltip">
                                                    <div className="pdf-docs-wrapper-icon docs-wrapper-icon" style={{ position: "static" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                        </svg>
                                                    </div>
                                                </Tippy>
                                            </div>

                                            :

                                            <>
                                                <div className="save-changes-2" onClick={() => {
                                                    updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
                                                }}>
                                                    Back To Questions
                                                </div>
                                            </>

                                        }
                                        {
                                            attempt > 0 ?

                                            <div className="show-prev" onClick={() => {
                                                setShowResults(true);
                                            }}>
                                                Show previous results
                                            </div>

                                            :

                                            <>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </>

            }
        </>
    );
}

export default UploadAndViewTests;