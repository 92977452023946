async function uploadFile(file, url, fileType) {
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': fileType,
            },
            body: file,
        });

        let data = {
            detail: "<No message>"
        }

        try {
            data = await response.json();
        } catch (error) {
            console.log(error)
        }
        
        if (response.status !== 200) {
            alert("Status Code: " + response.status + " Error: " + data.detail);
        }
        
        return response.status
    } catch (error) {
        console.error("Error uploading file:", error);
        alert("Error: " + error.message);
        return 400;
    }
}

export default uploadFile;