import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "./loading";
import Tippy from "@tippyjs/react";
import ASingleTestGrading from "./aSingleTestGrading";
import LoadingSpinner from "./loadingSpinner";

const RunNewTestsShortResponse = ({ saveAnswersCritical, updateQuestions, lastStartTime, questionsToSave, status, attempt, results, uuid, points }) => {
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);    
    const [flip, setFlip] = useState(false);

    const updateQuestionError = (newError) => {
        updateQuestions((prev) => {
            return prev.map((question) => {
                if (question.uuid !== uuid) return question;

                return {
                    ...question,
                    results: newError
                }
            })
        });
    }

    const fifteenMinutes = 15 * 60 * 1000;
    const hasBeenFifteenMinutes = !!(((new Date().getTime()) - (new Date(lastStartTime).getTime())) >= fifteenMinutes);
 
    const handleClick = async () => {
        if (requestLifeCycle) {
            return;
        }

        try {
            setRequestLifeCycle(true);
            if (questionsToSave && uuid in questionsToSave) {
                await saveAnswersCritical(true, uuid);
            }          
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/test-ai-short-response/${courseCode}/${assessmentCode}/${uuid}`
            const urlOptions = {
                method: "POST",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                updateQuestions((prev) => {
                    return prev.map((question) => {
                        if (question.uuid !== uuid) return question;

                        return {
                            ...question,
                            status: 'RUNNING',
                            attempt: (question.attempt !== null && question.attempt !== undefined) ? (question.attempt + 1) : 1,
                            lastStartTime: new Date()
                        }
                    })
                });
                
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                updateQuestionError("Status Code: " + response.status + " Error: " + data.detail)
            }

        } catch (error) {
            console.log(error);
            updateQuestionError("Error: " + error.message);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    const fetchResults = async () => {
        if (requestLifeCycle) {
            return;
        }

        try {
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/fetch-lambda-results/${courseCode}/${assessmentCode}/${uuid}?no_questions=${true}`
            const urlOptions = {
                method: "GET",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                if (data.status !== "RUNNING") {
                    updateQuestions((prev) => {
                        return prev.map((question) => {
                            if (question.uuid !== uuid) return question;
    
                            return {
                                ...question,
                                status: data.status,
                                attempt: data.attempt,
                                results: data.results
                            }
                        })
                    });
                }

                updateQuestions((prev) => {
                    return prev.map((question) => {
                        if (question.uuid !== uuid) return question;

                        return {
                            ...question,
                            lastStartTime: data.lastStartTime
                        }
                    })
                });
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                updateQuestionError("Status Code: " + response.status + " Error: " + data.detail);
            }

        } catch (error) {
            console.log(error);
            updateQuestionError("Error: " + error.message);
        }
    }

    const findScore = () => {
        try {
            return Number(results.score)
        } catch (catchError) {
            console.log(catchError)

            return "0"
        }
    }

    const findFeedback = () => {
        try {
            return results.feedback
        } catch (catchError) {
            console.log(catchError)
            
            return ""
        }
    }

    useEffect(() => {        
        const interval = setInterval(() => {
            if (status === 'RUNNING' && !hasBeenFifteenMinutes) {
                fetchResults();
                setFlip(prev => !prev);
            }            
            
        }, 1000);
    
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };

    }, [flip, status, lastStartTime]);

    return (
        <>
            {
                requestLifeCycle ?

                <div className="loading-zip-container" style={{ height: "50px" }}>
                    <Loading />
                </div>

                :

                <div className="run-new">
                    <div className="tests-center" style={{ marginBottom: "10px", columnGap: "10px" }}>
                        <div className="top-right-item run-tests" onClick={handleClick}>
                            Run AI
                            <Tippy placement="top" content={<div style={{ fontSize: '12px', textAlign: "center" }}>Run AI on question configuration (will autosave)</div>} theme="custom-tooltip">
                                <div className="pdf-docs-wrapper-icon docs-wrapper-icon" style={{ position: "static", border: "1px solid white" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                        <path stroke="white" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                    </svg>
                                </div>
                            </Tippy>
                        </div>
                    </div>
                    {                    
                        attempt && attempt > 0 ?
                            <>
                                <div className="attempt-processing-container">
                                    Attempt {attempt}{status === 'RUNNING' && !hasBeenFifteenMinutes ? " is processing" : ""}: {status === 'RUNNING' && !hasBeenFifteenMinutes ? <LoadingSpinner /> : <></>}
                                </div>
                                <div className="true-false-top lambda-results-container">
                                    {
                                        typeof results === "object" && results !== null ?

                                        <ASingleTestGrading name={"AI Feedback"} instructorPage={false} greyedOut={status === "RUNNING"} pointsEarned={findScore()} output={findFeedback()} totalPoints={points}/>

                                        :

                                        (
                                            results ?
                                            
                                            <div style={{ color: status === 'RUNNING' ? "var(--gray-six)" : "" }}>
                                                {results}
                                            </div>

                                            :

                                            <>
                                            </>
                                        )
                                    }
                                </div>
                            </>
                        :

                        <>
                        </>
                    }
                </div>
            }
        </>    
    );
}

export default RunNewTestsShortResponse;