import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const FeedbackWindow = (props) => {
    const { courseCode, assessmentCode, email } = useParams();
    const [feedback, setFeedback] = useState(props.feedback);

    const handleChange = (e) => {
        updateFeedback(e.target.value)
    }

    function updateFeedback(feedback) {
        setFeedback(feedback);
    }

    const submitForm = async () => {
        if (feedback === props.feedback) {
            return;
        }

        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + `/update-feedback/${courseCode}/${assessmentCode}/${email}`
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    feedback: feedback,
                    feedbackCode: props.feedbackCode
                })
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                props.updateFeedbackAssessmentInformation(data.feedback);
            }
            else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                alert("Status Code: " + response.status + " Error: " + data.detail);
            }

        } catch (error) {
            alert("Error: " + error.message);
            console.log(error);
        }
    }

    const handleBlur = async () => {
        props.handleBlur();
        await submitForm();
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            submitForm();
        }, 500)

        return () => {
            clearTimeout(timer);
        }

    }, [feedback])

    return (
        <>
            <div className="remaining">
                <textarea className="feedback-window-container" onFocus={props.handleFocus} onBlur={handleBlur} value={feedback} onChange={handleChange}/>
                <div className="remaining-bar" style={{ right: "0px" }}>
                    ≈400KB Max
                </div>
            </div>
        </>
    );
}

export default FeedbackWindow;