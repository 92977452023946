import React, { useEffect, useState } from "react";
import useWindowWidth from "./useWindowWidth";
import Remove from "./remove";
import { useParams } from "react-router-dom";

const Student = ({ name, email, decodedEmail, updateStudent, updateRemoveStudent, updateAddTime, studentId }) => {
    const width = useWindowWidth(685);
    const [componentStudentId, setComponentStudentId] = useState(studentId);
    const { courseCode } = useParams();

    const handleRemoveClick = () => {
        updateStudent(name, email, decodedEmail);
        updateRemoveStudent(true);
    };

    const handleTimeClick = () => {
        updateStudent(name, email, decodedEmail);
        updateAddTime(true);
    };

    const updateStudentId = async () => {
        try {
            const url = process.env.REACT_APP_COURSE_API_URL + `/update-student-id/${courseCode}/${email}/${componentStudentId}`;
            const urlOptions = {
                method: 'POST',
                credentials: 'include'
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();

            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else if (response.status !== 200) {
                alert("Status Code: " + response.status + " Error: " + data.detail);
            }

        } catch (error) {
            alert("Error: " + error.message);
            console.log(error);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if ( studentId && componentStudentId && componentStudentId !== studentId) {
                updateStudentId();
            }

        }, 500)

        return () => {
            clearTimeout(timer);
        }

    }, [componentStudentId])

    return (
        <>
            <div className="exam-container">
                <div className="course-metadata-2">
                    <div className="roster-meta">
                        {width ? (
                            <>
                                <div className="color one roster-student small" style={{ marginRight: "20px" }}>
                                    <div className="name-student">
                                        <div className="name-student-2" onClick={handleRemoveClick}>
                                            <Remove /> {name}
                                        </div>
                                    </div>
                                </div>
                                <div className="color roster-student small" style={{ textAlign: "right" }}>
                                    {decodedEmail ? decodedEmail : email}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="color roster-student" style={{ marginRight: "20px", width: "35%" }}>
                                    <div className="name-student">
                                        <div className="name-student-2" onClick={handleRemoveClick}>
                                            <Remove /> {name}
                                        </div>
                                    </div>                              
                                </div>
                                <div className="color roster-student handle-status" style={{ width: "30%", marginRight: "5px" }}>
                                    {decodedEmail ? decodedEmail : email}
                                </div>
                                <div className="color roster-student" style={{ width: "20%" }}>
                                    <input className="student-id" value={componentStudentId} onChange={(e) => {setComponentStudentId(e.target.value)}} onBlur={updateStudentId}/>
                                </div>
                                <div className="color roster-student three">
                                    <div className="extra-time">
                                        <div className="extra-time-2" onClick={handleTimeClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path fill="rgb(60,60,60)" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Student;
