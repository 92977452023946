import React, { useState } from "react";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Regrade from "./regrade";
import Tippy from "@tippyjs/react";
import AiFeedback from "./aiFeedback";
import keyValueUpdate from "./keyValueUpdate";

const TextResponseView = ({ updateAssessmentInformation, autoAssign, scoreStatus, useAi, results, status, id, updateId, isDarkTheme, useCustomScore, questionNumber, question, points, grading, pointsEarned, uuid, studentAnswer }) => {
    const [answer, setAnswer] = useState(studentAnswer);

    const updateAField = (key, value) => {
        updateAssessmentInformation((prev) => {
            return keyValueUpdate(prev, uuid, key, value);
        })
    }

    function updateComponentPointsEarned(newValue) {        
        updateAField("pointsEarned", newValue);
    }
    
    function updateUseCustomScoreFromGrading(bool) {
        updateAField("useCustomScore", bool);
    }

    function updateComponentScoreStatus(newStatus) {        
        updateAField("scoreStatus", newStatus);
    }    

    function updateAnswer(e) {
        if (!grading) {
            setAnswer(e.target.value);
        }
    }

    const findScore = () => {
        try {
            return Number(results.score)
        } catch (error) {
            console.log(error)

            return "0"
        }
    }

    const findFeedback = () => {
        try {
            return results.feedback
        } catch (error) {
            console.log(error)

            return ""
        }
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        {
                            grading && (
                                <Tippy content={uuid === id ? "Deselect Answer" : "Select Answer"} placement="right">
                                    <input type={"checkbox"} className="check-auto" checked={uuid === id} onChange={() => {updateId(uuid === id ? "" : uuid)}} style={{ cursor: "pointer" }}/>
                                </Tippy>
                            )
                        }
                    </div>
                    <div className="pts">
                        {
                            useCustomScore && <Regrade previousScore={pointsEarned} updateComponentPointsEarned={updateComponentPointsEarned} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading} uuid={uuid}/>
                        }
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion previousScore={pointsEarned} updateComponentPointsEarned={updateComponentPointsEarned} updateComponentScoreStatus={updateComponentScoreStatus} pointsEarned={pointsEarned} uuid={uuid} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" value={answer} onChange={updateAnswer} style={{ minHeight: grading ? "10px" : "" }} disabled={grading}/>
                </div>
                {
                    grading && results && useAi && (
                        <>
                            {
                                typeof results === "object" ?

                                <div className="select-all-that-apply-array">
                                    <AiFeedback updateComponentScoreStatus={updateComponentScoreStatus} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading} uuid={uuid} updateComponentPointsEarned={updateComponentPointsEarned} autoAssign={autoAssign} scoreStatus={scoreStatus} name={"AI Feedback"} greyedOut={status === "RUNNING"} pointsEarned={findScore()} output={findFeedback()} totalPoints={points}/>         
                                </div>

                                :

                                <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "", width: "100%", padding: "5px", fontSize: "var(--font-size-one)" }}>
                                    {results}
                                </div>

                            }
                        </>
                    )
                }
            </div>
        </>
    );
}

export default TextResponseView;