import React from "react";

const Explanation = ({ form, updateForm, submitForm }) => {
    return (
        <>
            <div className="message-container" style={{ alignItems: "center" }}>
                Explanation:
                <input style={{ cursor: "pointer" }} className="check-auto" onBlur={submitForm} type="checkbox" checked={form.useExplanation} onChange={(e) => updateForm("useExplanation", e.target.checked)} />
            </div>
            {
                form.useExplanation && (
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Enter explanation (render Markdown and LaTeX)" style={{ minHeight: "100px" }} value={form.explanation} onChange={(e) => {
                        updateForm("explanation", e.target.value);
                    }}/>
                )   
            }
        </>
    );
}

export default Explanation;