import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

const Copy = (props) => {
    const [copied, setCopied] = useState(false); // State to track whether text is copied

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(props.text);
            setCopied(true); // Change SVG after copying

            // Wait 2 seconds, then revert SVG
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    return (
        <>
                <div className="copy" onClick={copyToClipboard}>
                    {copied ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                        </svg>
                    ) : (
                        <Tippy content={"Copy Code"} placement="top" theme="custom-tooltip">         
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                                </svg>
                            </div>
                        </Tippy>
                    )}
                </div>
        </>
    );
};

export default Copy;
