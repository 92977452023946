import React, { useEffect, useState } from "react";
import useWindowWidth from "./useWindowWidth";
import { useParams, useNavigate } from "react-router-dom";
import convertDate from "./convertDate";
import CustomSwitch from "./customSwitch";
import Regenerate from "./regenerate";
import Trash from "./trash";
import intToLetters from "./intToLetters";
import Loading from "./loading";
import handleStatusDate from "./handleStatusDate";
import HandleStatus from "./handleStatus";
import EditAssessmentForm from "./editAssessmentForm";
import Tippy from "@tippyjs/react";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import ReloadButton from "./reloadButton";
import autograderAttemptsWarning from "./autograderAttemptsWarning";

const LeftInfoSettings = ({ updateDeleteDocker, requestLifeCycle, assessmentInformation, updateAssessmentInformationParent, updateDeleteForm, updateDeleteZip }) => {
    const width = useWindowWidth(850);
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();    
    const [initAttemptsCall, setInitAttemptsCall] = useState(true);
    const [initAttemptsPCall, setInitAttemptsPCall] = useState(true);
    const [error, setError] = useState(null);
    const [requestLifeCycleDockerfile, setRequestLifeCycleDockerfile] = useState(false);
    const [open, setOpen] = useState(false);
    
    const submitForm = async () => {       
        if (initAttemptsCall) {
            setInitAttemptsCall(false);
            return;
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-attempts";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                attempts: assessmentInformation.attempts
            })
        };
        
        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                updateAssessmentInformation("attempts", data.attempts)
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                alert("Status Code: " + res.status + " Error: " + data.detail);
            }
        } catch (error) {
            alert("Error: " + error.message)
            console.log(error);
        }
    }
    
    const submitFormP = async () => {   
        if (initAttemptsPCall) {
            setInitAttemptsPCall(false);
            return;
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-attempts-programming";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                attempts: assessmentInformation.allowedAttemptsProgramming
            })
        };
        
        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                updateAssessmentInformation("allowedAttemptsProgramming", data.attempts)
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            submitForm();
        }, 500)

        return () => {
            clearTimeout(timer)
        }
        
    }, [assessmentInformation.attempts])

    useEffect(() => {
        const timer = setTimeout(() => {
            submitFormP();
        }, 500)

        return () => {
            clearTimeout(timer)
        }
        
    }, [assessmentInformation.allowedAttemptsProgramming])

    function updateAssessmentInformation(type, value, switchClick = false) {
        updateAssessmentInformationParent((prev) => ({
            ...prev,
            [type]: value
        }))

        if (switchClick) {
            handleSwitchClick(value, type);
        }
    }

    const handleCodeClick = async () => {
        const url = process.env.REACT_APP_EVALUATION_API_URL + `/regenerate-entry-code`

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                entryCode: assessmentInformation.entryCode,
                assessmentCode: assessmentCode
            })
        }

        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200 && data.entryCode) {
                updateAssessmentInformation("entryCode", data.entryCode)
            }
            else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    const handleSwitchClick = async (boolVal, updateVal) => {
        const url = process.env.REACT_APP_EVALUATION_API_URL + "/evaluation-item-toggle";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                boolVal: boolVal,
                updateVal: updateVal,
                courseCode: courseCode,
                start: assessmentInformation.start,
                end: assessmentInformation.end,
                assessmentCode: assessmentCode,
                isProgramming: assessmentInformation.isProgramming,
                duration: assessmentInformation.duration.toString(),
                metric: assessmentInformation.metric
            })
        };

        try {
            const res = await fetch(url, urlOptions);
            if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    const handleFormClick = (event, formIndex) => {
        const queryParams = new URLSearchParams({
            formIndex
        });

        queryParams.set("tab", "PDF");

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit?${queryParams}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit?${queryParams}`);
        }
    }

    const handleZipClick = (event) => {
        const tab = TypesOfLocationEnum.ZIP

        const queryParams = new URLSearchParams({
            tab
        }).toString();

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit?${queryParams}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit?${queryParams}`);
        }
    }

    const handleDockerClick = (event) => {
        const tab = TypesOfLocationEnum.DOCKER

        const queryParams = new URLSearchParams({
            tab
        }).toString();

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit?${queryParams}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit?${queryParams}`);
        }
    }

    const status = handleStatusDate(assessmentInformation.start, assessmentInformation.end, assessmentInformation.submissionsLength);

    const findJsonDockerLog = () => {
        try {
            const data = JSON.parse(assessmentInformation.dockerLogs).log
            if (data) {
                return data
            }

            return null;

        } catch (error) {
            console.log(error);
            return "Docker Log is not valid JSON"
        }
    }

    const findShowDockerLogs = () => {
        try {
            const data = JSON.parse(assessmentInformation.dockerLogs).log
            if (data) {
                return true
            }

            return false;

        } catch (error) {
            console.log(error);
            return false
        }
    }

    const handleRefresh = async () => {
        if (requestLifeCycle) {
            return;
        }
        
        try {
            setRequestLifeCycleDockerfile(true);
            const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-settings/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await res.json();
            if (res.status === 200) {
                console.log()
                setError(null);
                updateAssessmentInformationParent(data);
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setError(data.detail);
            }
    
        } catch (error) {
            setError(error.message);
            console.log(error);
        } finally {
            setRequestLifeCycleDockerfile(false);
        }
    }

    return (
        <>
            <div className="middle-content" style={{ borderLeft: width ? "none" : "", borderRight: width ? "none" : "" }}>
                {
                    requestLifeCycle

                    ?

                    <>
                        <div className="loading-container">
                            <Loading />
                        </div> 
                    </>

                    :

                    <>
                        {
                            assessmentInformation && Object.keys(assessmentInformation).length > 0

                            ?

                            <>
                                <div className="side-nav-info-header" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <span className="assessment-name">
                                        {assessmentInformation.name}
                                    </span>
                                </div>
                                <div className="side-nav-info-header">
                                    Status
                                </div>
                                <div className="text-side-nav display-2">
                                    { HandleStatus(status) } { status === "Submitted" ? "Closed" : (status !== "Some-Submitted" ? status : assessmentInformation.submissionsLength) }
                                </div>
                                <div className="side-nav-info-header">
                                    Schedule
                                </div>
                                <div className="text-side-nav display">
                                    <div> { "Duration: " + (assessmentInformation.duration === -1 || assessmentInformation.attempts === -1 ? "Full-time" : assessmentInformation.duration + " " + assessmentInformation.metric) } </div>
                                    <div> { "Available From: " + convertDate(assessmentInformation.start, false) } </div>
                                    <div> { "Available Until: " + convertDate(assessmentInformation.end, false) } </div>
                                </div>
                                {
                                    assessmentInformation.formIds.length > 0 

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", alignItems: "center" }}>
                                            PDF Forms
                                            <Trash updateDeleteForm={updateDeleteForm}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>

                                }
                                <div className="text-side-nav display" style={{ marginTop: "-14px" }}>
                                    {
                                        assessmentInformation.formIds.map((formId, index) => {
                                            return <div key={formId} className="form-id" onClick={(e) => {
                                                handleFormClick(e, index);
                                            }}> Form { intToLetters(index) } </div>
                                        })
                                    }
                                </div>
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                    Attempts
                                </div>
                                <div className="text-side-nav display">
                                    <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                        {
                                            assessmentInformation.attempts !== -1
                                            
                                            ?

                                            <input type="number" className="grade-box-enter entry-exit" value={assessmentInformation.attempts} onChange={(e) => {
                                                updateAssessmentInformation("attempts", e.target.value, false);
                                            }} onBlur={submitForm}/>

                                            :
                                            
                                            <>
                                            </>
                                        }
                                        <input type="checkbox" checked={assessmentInformation.attempts === -1} onChange={() => {
                                            if (assessmentInformation.attempts === -1) {
                                                updateAssessmentInformation("attempts", 1, false);  
                                            } else {
                                                updateAssessmentInformation("attempts", -1, false);  
                                            }                                                           
                                        }} onBlur={submitForm}/>
                                        <div style={{ textWrap: "nowrap" }}>
                                            Continuous
                                        </div>
                                        {
                                            assessmentInformation.attempts === -1 && (                                            
                                                <Tippy 
                                                    content={
                                                        <div style={{ textAlign: "center", padding: "5px", fontSize: "var(--font-size-one)" }}> 
                                                            {autograderAttemptsWarning}
                                                        </div>
                                                    }
                                                    placement="top"
                                                    theme="custom-tooltip">
                                                    <div className="docs-wrapper-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                        </svg>
                                                    </div>
                                                </Tippy>
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    assessmentInformation.isProgramming && assessmentInformation.zipFileExists

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", alignItems: "center" }}>
                                            Zip File
                                            <Trash updateDeleteForm={updateDeleteZip}/>
                                        </div>
                                        {
                                            assessmentInformation?.zipFileExists && assessmentInformation.zipFilename && (
                                                <div className="text-side-nav display" style={{ marginTop: "-10px" }} onClick={handleZipClick}>
                                                    <div className="form-id">
                                                        { assessmentInformation.zipFilename }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>

                                    :

                                    <>
                                    </>
                                }
                                {
                                    assessmentInformation.dockerfileExists

                                    ?
                                        <>
                                            <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", alignItems: "center" }}>
                                                Dockerfile
                                                <Trash updateDeleteForm={updateDeleteDocker}/>
                                            </div>
                                            <div className="docker-container">                                                        
                                                <div className="key-value">                                            
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="var(--accent-one)" color="var(--accent-one)" width="20" height="20" viewBox="0 0 640 512">
                                                        <path color="var(--accent-one)" d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z"/>
                                                    </svg>
                                                    <span className="dockerfile" onClick={handleDockerClick}>
                                                        Dockerfile
                                                    </span>
                                                    <div onClick={handleRefresh}>
                                                        <ReloadButton size={12.5}/>
                                                    </div>
                                                </div>
                                                {
                                                    requestLifeCycleDockerfile ?

                                                    <div className="loading-container" style={{ height: "100px" }}>
                                                        <Loading />
                                                    </div>
                
                                                    :

                                                    <>
                                                        {
                                                            error ? <div className="key-value">{error}</div> : 

                                                            <>
                                                                <div className="key-value">
                                                                    Build Start: {convertDate(assessmentInformation.lastDockerStartTime, true)}
                                                                </div>
                                                                <div className="key-value">
                                                                    Status: <span className="apply-remove remove">{assessmentInformation.dockerStatus === 'RUNNING' ? 'Running' : "Finished"}</span>
                                                                </div>
                                                                    <div className="metric-select question-2" onClick={() => {
                                                                            setOpen(prev => !prev)                                       
                                                                        }}>
                                                                        Errors
                                                                        <div className="center-chevron">
                                                                            {
                                                                                open ?

                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                                    <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                                                                                </svg>

                                                                                :

                                                                                
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                                    <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                                                                </svg>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                {
                                                                    open && findShowDockerLogs() ? <div className="key-value-logs"> {findJsonDockerLog()} </div> : <></>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }                                                
                                            </div>
                                        </>

                                    :

                                    <>
                                    </>
                                }
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                    Entry Code 
                                    <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useEntryCode", e.target.checked, true)}} checked={assessmentInformation.useEntryCode}/>
                                </div>
                                {
                                    assessmentInformation.useEntryCode

                                    ?

                                        <div className="text-side-nav display">
                                            <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                                <div type="number" className="grade-box-enter entry-exit">
                                                    {assessmentInformation.entryCode}
                                                </div>
                                                <div onClick={() => handleCodeClick()}>
                                                    <Regenerate />
                                                </div>
                                            </div>
                                        </div>

                                    :

                                    <>
                                    </>

                                }
                                {
                                    false && (
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                            <div className="container-for-tippy-lock">
                                                Lockdown Environment
                                                <Tippy content={"Lockdown evals can only be taken in desktop app"} placement="top" theme="custom-tooltip">
                                                    <div className="docs-wrapper-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                        </svg>
                                                    </div>
                                                </Tippy>
                                            </div>                                                                    
                                            <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useLockdownEnvironment", e.target.checked, true)}} checked={assessmentInformation.useLockdownEnvironment}/>                            
                                        </div>
                                    )
                                }                                
                                {
                                    assessmentInformation.isProgramming

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                            Syntax Highlighting
                                            <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useSyntaxHighlighting", e.target.checked, true)}} checked={assessmentInformation.useSyntaxHighlighting}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>
                                }
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", marginBottom: !assessmentInformation.zipFileExists ? "45px" : "" }}>
                                    Published Submissions
                                    <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("published", e.target.checked, true)}} checked={assessmentInformation.published}/>
                                </div>
                                {
                                    assessmentInformation.zipFileExists && (
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                            <div className="container-for-tippy-lock">
                                                Reset Zip File
                                                <Tippy content={<div style={{ textAlign: "center" }}>Let students reset their zip file to a new clean, initial evaluation zip file during the evaluation</div>} placement="top" theme="custom-tooltip">
                                                    <div className="docs-wrapper-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                        </svg>
                                                    </div>
                                                </Tippy>
                                            </div>
                                            <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("resetZipFile", e.target.checked, true)}} checked={assessmentInformation.resetZipFile}/>
                                        </div>
                                    )
                                }
                                {
                                    assessmentInformation.zipFileExists && (
                                        <>                                        
                                            <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%"}}>
                                                <div className="container-for-tippy-lock">
                                                    Additional Programming Attempts
                                                    <Tippy content={"Let students test code zip file during evaluation"} placement="top" theme="custom-tooltip">
                                                        <div className="docs-wrapper-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                                <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                            </svg>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            </div>
                                            <div className="text-side-nav display" style={{ marginBottom: "45px" }}>
                                                <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                                    {
                                                        assessmentInformation.allowedAttemptsProgramming !== -1
                                                        
                                                        ?

                                                        <input type="number" className="grade-box-enter entry-exit" value={assessmentInformation.allowedAttemptsProgramming} onChange={(e) => {
                                                            updateAssessmentInformation("allowedAttemptsProgramming", e.target.value, false);
                                                        }} onBlur={submitFormP}/>

                                                        :
                                                        
                                                        <>
                                                        </>
                                                    }
                                                    <input type="checkbox" checked={assessmentInformation.allowedAttemptsProgramming === -1} onChange={() => {
                                                        if (assessmentInformation.allowedAttemptsProgramming === -1) {
                                                            updateAssessmentInformation("allowedAttemptsProgramming", 0, false);  
                                                        } else {
                                                            updateAssessmentInformation("allowedAttemptsProgramming", -1, false);  
                                                        }                                                           
                                                    }} onBlur={submitFormP}/>
                                                    Unlimited
                                                    {
                                                        assessmentInformation.allowedAttemptsProgramming !== -1 && assessmentInformation.attempts === -1 && (
                                                            <Tippy content={<div style={{ fontSize: '12px', textAlign: "center" }}>{autograderAttemptsWarning}</div>} placement={"top"}>
                                                                <div style={{ cursor: "pointer" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff3f3f" viewBox="0 0 16 16">
                                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                                                    </svg>
                                                                </div>
                                                            </Tippy>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <EditAssessmentForm attempts={assessmentInformation.attempts} isProgramming={assessmentInformation.isProgramming} metric={assessmentInformation.metric} duration={assessmentInformation.duration} name={assessmentInformation.name} start={assessmentInformation.start} end={assessmentInformation.end} viewing={assessmentInformation.viewing} points={assessmentInformation.points}/>
                            </>

                            :

                            <>
                            </>
                        }
                    </>

                }
            </div>
        </>
    );
}

export default LeftInfoSettings;