import React from "react";

const LoadingSpinner = ({ white }) => {
    return (
        <>
            <span className={ white ? "loader-white" : "loader" }></span>
        </>
    );
}

export default LoadingSpinner;