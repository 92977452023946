import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';

const SmallBoxPlots = (props) => {
    useEffect(() => {
        const calculateAverage = (data) => {
            if (data.length > 0) {
                const sum = data.reduce((a, b) => a + b, 0);
                return (sum / data.length).toFixed(2);
            } else {
                return 0;
            }
        };

        const averageGrades = calculateAverage(props.gradesData);
        const averageTime = calculateAverage(props.timeData);

        // First chart: Grades
        const gradesTrace = {
            y: props.gradesData,
            type: 'box',
            name: 'Grades (points)',
            marker: { color: 'rgba(01, 105, 244, 255)' },
        };

        const gradesLayout = {
            title: 'Grades Distribution',
            margin: { t: 60, b: 60, l: 60, r: 60 },
            showlegend: false,
            annotations: [
                {
                    x: 0.5,
                    y: 1.05,
                    xref: 'paper',
                    yref: 'paper',
                    text: `Average Score: ${averageGrades}`,
                    showarrow: false,
                    font: { size: 14, color: 'rgba(01, 105, 244, 255)' }
                }
            ]
        };

        Plotly.newPlot('gradesDiv', [gradesTrace], gradesLayout, { displayModeBar: false });

        // Second chart: Time
        const timeTrace = {
            y: props.timeData,
            type: 'box',
            name: 'Time (min)',
            marker: { color: 'rgba(106, 90, 205, 1)' },
        };

        const timeLayout = {
            title: 'Time Spent Distribution',
            margin: { t: 60, b: 60, l: 60, r: 60 },
            showlegend: false,
            annotations: [
                {
                    x: 0.5,
                    y: 1.05,
                    xref: 'paper',
                    yref: 'paper',
                    text: `Average Time: ${averageTime}`,
                    showarrow: false,
                    font: { size: 14, color: 'rgba(106, 90, 205, 1)' }
                }
            ]
        };

        Plotly.newPlot('timeDiv', [timeTrace], timeLayout, { displayModeBar: false });
    }, [props.gradesData, props.timeData]);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: "100%" }}>
            <div id="gradesDiv" style={{ width: '45%', height: '400px' }}></div>
            <div id="timeDiv" style={{ width: '45%', height: '400px' }}></div>
        </div>
    );
};

export default SmallBoxPlots;