import React from "react";
import ViewDocs from "./viewDocs";

const SelectZipFile = (props) => {

    const handleChange = (e) => {
        const zip = e.target.files[0];
        props.updateValue(zip);
    }

    return (
        <>
            <div className="input-field" style={{ height: "auto" }}>
                <div className="top-input">
                    { props.top }                     
                    {
                        props.star

                        ?

                        <span className="required-star">
                            *
                        </span>

                        :

                        <>
                        </>
                    }
                    {
                        !props.upload && (
                            <div>
                                <ViewDocs path={'/autograder-docs'}/>
                            </div>
                        )
                    }
                </div>
                <div className="pdf-upload-container" style={{ height: "30px" }}>
                    <input type="file" className="pdf-choose" onChange={handleChange} required={ props.required } accept={props.accept}/>
                </div>
            </div>
        </>
    );
}

export default SelectZipFile;