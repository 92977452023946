import React from "react";
import InstructorInfo from "./instructorInfo";
import Roster from "./roster";
import Server from "./server";
import TrashCan from "./trashCan";
import Duplicate from "./duplicate";
import BlackClipboard from "./blackClipboard";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const NavInfoCourse = (props) => {
    const navigate = useNavigate();
    const { courseCode } = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    
    const handleClick = (path, event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${path}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${path}`);
        }   
    }

    return (
        <>
            <div className="semester-nav-info">
                <div className="side-nav-info-header">
                    { props.name }
                </div>
                <div className={`open-nav ${!currentPath.endsWith("/roster") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ (e) => {handleClick("", e);} }>
                    <Server /> Dashboard
                </div>
                <div className={`open-nav ${currentPath.endsWith("/roster") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ (e) => {handleClick("roster", e);} }>
                    <Roster /> Roster
                </div>
                <div className="side-nav-info-header">
                    Actions
                </div>
                <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => {props.updateCourse(true);} }>
                    <BlackClipboard /> Update Course
                </div>
                <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => {props.updateDuplicate(true);} }>
                    <Duplicate /> Duplicate Course
                </div>
                <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => {props.updateDeleteCourse(true);} }>
                    <TrashCan /> Delete Course
                </div>
                {
                    props.showTransfer && (
                        <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => {props.updateTransfer(true);} }>
                            <div className="icon-3" style={{ border: "none", borderRadius: "0px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} color="currentColor" viewBox="0 0 448 512">
                                    <path d="M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z"/>
                                </svg>
                            </div>                    
                            Transfer Ownership
                        </div>
                    )
                }
                <div className="side-nav-info-header">
                    { props.instructors.length > 1 ? "Instructors" : "Instructor" }
                </div>
                <div className="meta-info-side">
                    <div className="meta-info-side-container">
                        {
                            props.instructors.map((instructor) => {
                                return <InstructorInfo key={instructor.email} instructor={instructor.name} email={instructor.email} updateInstructor={props.updateInstructor} updateRemove={props.updateRemove}/>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavInfoCourse;