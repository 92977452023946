import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import MetricSelect from "./metricSelect";
import moment from "moment-timezone";
import LittleX from "./littleX";
import MultipleForms from "./multipleForms";
import Info from "./info";
import SelectZipFile from "./selectZipFile";
import validateZipFile from "./validateZipFile";
import uploadFile from "./uploadFileToS3";
import PdfTippy from "./pdfTippy";
import validateDockerfile from "./validateDockerfile";

const AddProgrammingEvalForm = (props) => {
    const navigate = useNavigate();
    const [text, setText] = useState("Enter the evaluation information below");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode } = useParams();
    const [metric, setMetric] = useState("minutes");
    const [form, setForm] = useState({
        courseCode: courseCode,
        name: "",
        start: "",
        end: "",
        viewing: false,
        pdfs: "NA",
        duration: -1,
        zipFile: "NA",
        dockerfile: "NA"
    })

    function updateDockerfile(dockerfile) {
        setForm(prevForm => ({
            ...prevForm,
            dockerfile: dockerfile,
        }))
    }

    function updateMetric(metric) {
        setMetric(metric);
    }

    function updateName(name) {
        setForm(prevForm => ({
            ...prevForm,
            name: name,
        }))
    }

    function updateStart(start) {
        setForm(prevForm => ({
            ...prevForm,
            start: start,
        }))    
    }

    function updateEnd(end) {
        setForm(prevForm => ({
            ...prevForm,
            end: end,
        }))
    }

    function updatePdfs(pdfs) {
        setForm(prevForm => ({
            ...prevForm,
            pdfs: pdfs,
        }))
    }

    function updateZipFile(zipFile) {
        setForm(prevForm => ({
            ...prevForm,
            zipFile: zipFile,
        }))
    }

    function updateDuration(duration) {
        setForm(prevForm => ({
            ...prevForm,
            duration: duration,
        }));
    }

    const resetForm = () => {
        props.updateCreating(false);
    }

    const validateDockerfileAsync = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            
            reader.onload = (event) => {
                const content = event.target.result;
                const [isValid, msg] = validateDockerfile(content);
    
                if (!isValid) {
                    setText(msg);
                    reject(new Error("Invalid Dockerfile"));
                } else {
                    resolve();
                }
            };
    
            reader.readAsText(file);
        });
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        // form validation
        for (const value in form) {
            if (form[value] === "") {
                setText("Please fill out all required fields")
                return;
            }
        }

        if (form.dockerfile !== "NA" && form.dockerfile?.name?.toLowerCase() !== 'dockerfile') {            
            setText("Dockerfile input's name is not \"Dockerfile\"");
            return;
        }

        if (form.dockerfile !== "NA") {
            try {
                await validateDockerfileAsync(form.dockerfile);
            } catch (error) {
                console.log(error);
                return;
            }
        }       

        if (form.duration === 0) {
            setText("Please enter a valid duration");
            return;
        }

        if (form.name.length > 100) {
            setText("Evaluation name must be 99 characters or fewer")
            return;
        }

        if (form.name.trim() === '') {
            setText("Enter a valid name")
            return;
        }

        const formData = new FormData();
        formData.append('courseCode', form.courseCode);
        formData.append('name', form.name);
        formData.append('start', new Date(form.start).toISOString());
        formData.append('end', new Date(form.end).toISOString());
        formData.append('viewing', false);
        formData.append('duration', form.duration);
        formData.append('metric', metric);
        formData.append('isProgramming', true);

        if (form.pdfs !== "NA") {
            formData.append('pdfs', form.pdfs.length);
        } else {
            formData.append('pdfs', 0);
        }

        const maxSizeInBytes = 50 * 1024 * 1024; // 50MB

        if (form.pdfs !== "NA") {
            for (let file of form.pdfs) {
                if (!(file.size <= maxSizeInBytes)) {
                    setText("At least one pdf exceeds 50MB");
                    return;
                }
            }
        }
        
        if (form.zipFile !== "NA") {
            const [passed, message] = await validateZipFile(form.zipFile);
            if (!passed) {
                setText("Click cancel to reset: " + message);
                return;
            }
            formData.append('zipFile', 1);
            formData.append('zipFilename', form.zipFile.name);
        } else {
            formData.append('zipFile', 0);
            formData.append('zipFilename', "NA");
        }

        if (form.dockerfile !== "NA") {
            formData.append('dockerfile', 1);
        } else {
            formData.append('dockerfile', 0);
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/create-assessment"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
            },
            body: formData
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                if (data.urls && form.zipFile !== "NA" && data.urls.zipFileUrl) {
                    const zipStatus = await uploadFile(form.zipFile, data.urls.zipFileUrl, "application/zip");
                    
                    if (zipStatus !== 200) {
                        setText("Zip file failed to upload");
                        return;
                    }
                }

                if (data.urls && form.dockerfile !== "NA" && data.urls.dockerfileUrl) {
                    const imageStatus = await uploadFile(form.dockerfile, data.urls.dockerfileUrl, "text/plain");
                    
                    if (imageStatus !== 200) {
                        setText("Docker image failed to upload");
                        return;
                    }
                }

                if (data.urls && form.pdfs !== "NA" && data.urls.pdfUrls) {
                    const urls = data.urls.pdfUrls;
                    const uploadPromises = form.pdfs.map((value, index) => {
                        return uploadFile(value, urls[index], "application/pdf");
                    });
                
                    try {
                        const results = await Promise.all(uploadPromises);
                        if (results.some(result => result !== 200)) {
                            setText("Pdf failed to upload");
                            return;
                        }
                    } catch (error) {
                        setText("Pdf failed to upload");
                        return;
                    }
                }
                
                setText("Evaluation created")
                props.updateCreating(false);
                navigate(`/${courseCode}/${data.assessmentCode}/edit`)
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL +  "login";
            } else if (res.status === 400) {
                setText(res.detail)
            } else if (res.status === 410) {
                setText("Please try again")
            } else if (res.status === 409) {
                setText("Start date is after or equal to end date")
            } else if (res.status === 405) {
                setText("File uploaded was not a zip file")
            } else if (res.status === 207) {
                setText("Evaluation created: please upload pdf from evaluation settings")
            } else {
                setText("Status Code: " + res.status + " Error: " + data.detail)
            }

        } catch (error) {
            setText("Error: " + error.message)
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
        
    }

    useEffect(() => {
        if (form.start && form.end) {
          const start = moment(form.start);
          const end = moment(form.end);
          
          if (start.isAfter(end)) {
            setForm(prevForm => ({
              ...prevForm,
              end: ""
            }));
          }
        }
      }, [form.start]);
    
    useEffect(() => {
        if (form.start && form.end) {
            const start = moment(form.start);
            const end = moment(form.end);
            
            if (end.isBefore(start)) {
            setForm(prevForm => ({
                ...prevForm,
                start: ""
            }));
            }
        }
    }, [form.end]);

    useEffect(() => {
        setText("Enter the evaluation information below");
    }, [form, metric])

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateCreating(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Create New Evaluation
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                <div className="required">
                                    *
                                </div> Required Field
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <CourseInput star={true} value={form.name} updateValue={updateName} top={"Evaluation Name"} type={"text"} required={true} autoComplete={"off"} placeholder={"Ex. Midterm 1"}/>
                            <CourseInput star={true} value={form.start} updateValue={updateStart} top={"Available From"} type={"datetime-local"} required={true} autoComplete={"off"} placeholder={"Select date and time"}/>
                            <CourseInput star={true} value={form.end} updateValue={updateEnd} top={"Available Until"} type={"datetime-local"} required={true} autoComplete={"off"} placeholder={"Select date and time"}/>
                            <div className="points-viewing-container">
                                <div style={{ width: "45%", position:'relative' }}>
                                    <SelectZipFile accept={""} star={false} value={form.dockerfile} updateValue={updateDockerfile} top={"Dockerfile"} required={true}/>                                    
                                </div>
                                <div style={{ width: "45%", position: "relative" }}>
                                    <SelectZipFile accept={"application/zip"} star={false} value={form.zipFile} updateValue={updateZipFile} top={"Zip File"} required={true}/>                                    
                                </div>
                            </div>                           
                            <div className="points-viewing-container" style={{ width: "100%", justifyContent: "space-between" }}>
                                <div style={{ width: "45%", position: "relative" }}>                                    
                                    <MultipleForms value={form.pdfs} updateValue={updatePdfs} top={"PDF Form(s)"} required={false}/>
                                    <PdfTippy left={"85px"}/>
                                </div>
                                {
                                    false && (
                                        <div className="points-viewing-container" style={{ width: "45%", position: "relative" }}>
                                            {
                                                form.duration === -1 

                                                ?

                                                <>
                                                    <div className="input-field">
                                                        <div className="top-input">
                                                            Full-time Duration
                                                            <span className="required-star">
                                                                *
                                                            </span>
                                                        </div>
                                                        <input style={{ width: "auto", marginTop: "-10px", cursor: "pointer" }} className="bottom-input" type={ "checkbox" } checked={ true } required={true} onChange={ () => {
                                                            updateDuration(0);
                                                        } }/>
                                                    </div>
                                                </>

                                                :

                                                <>
                                                    <LittleX updateValue={updateDuration}/>
                                                    <div style={{ width: "45%" }}>
                                                        <CourseInput star={true} top={"Duration"} value={form.duration} updateValue={updateDuration} type={"number"} required={true} autoComplete={"off"} min={0}/>
                                                    </div>
                                                    <div style={{ width: "45%" }}>
                                                        <MetricSelect star={true} top={"Metric"} value={metric} updateValue={updateMetric} metrics={
                                                            [
                                                                {
                                                                    name: "Minutes",
                                                                    value: "minutes"
                                                                },
                                                                {
                                                                    name: "Hours",
                                                                    value: "hours"
                                                                },
                                                                {
                                                                    name: "Days",
                                                                    value: "days"
                                                                }
                                                        ]
                                                            
                                                        } type={"number"} required={true} autoComplete={"off"} min={0}/>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Create Evaluation", large : "Create Evaluation" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default AddProgrammingEvalForm;