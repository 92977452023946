import React from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import { useNavigate, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";

const LocationSettingsItem = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const setUrl = () => {
        // Existing query parameters
        const searchParams = new URLSearchParams(location.search);

        // Add or modify a query parameter
        searchParams.set('tab', props.path); // Adds or updates 'newParam'

        // Build the new query string
        const newSearch = searchParams.toString();

        // Navigate to the same path with the updated query params
        navigate({
            pathname: location.pathname,
            search: newSearch,
        });
    }

    const factory = () => {
        switch (props.path) {
            case TypesOfLocationEnum.CUSTOM_EXAM: return "Evaluation"
            case TypesOfLocationEnum.SETTINGS: return "Details And Evaluation"
            case TypesOfLocationEnum.ZIP: return "Zip File"
            case TypesOfLocationEnum.PDF: return "PDF"
            case TypesOfLocationEnum.TESTS: return "Test Zip File"
            case TypesOfLocationEnum.DOCKER: return "Dockerfile"
            case TypesOfLocationEnum.ERROR: return "Error"

            default: return "Blank Tab"
        }
    }

    return (
        <>
            <Tippy placement={"top"} content={factory()}>
                <div className="location-settings-hover-item" onClick={() => {
                    setUrl()              
                    props.updateCurrentViewer(props.path)
                }}>
                    { props.svgOne }
                </div>
            </Tippy>
        </>
    );
}

export default LocationSettingsItem;