import React, { useState } from "react";
import Nav from "./nav";
import SideNav from "./sideNav";

const Navigation = (props) => {
    const [open, setOpen] = useState(false);

    function updateOpen(open) {
        setOpen(open);
    }

    return (
        <>
            <Nav updateText={props.updateText} text={props.text} saveAnswersCritical={props.saveAnswersCritical} tab={props.currentViewer} id={props.id} updateCreating={props.updateCreating} creating={props.creating} updateQuestionsToSave={props.updateQuestionsToSave} questionsToSave={props.questionsToSave} updateIsEditing={props.updateIsEditing} isEditing={props.isEditing} updateAddAQuestion={props.updateAddAQuestion} isProgramming={props.isProgramming} formIds={props.formIds} isCursorInside={props.isCursorInside} updateOpen={updateOpen} open={open} bool={props.bool} boolTwo={props.boolTwo} boolThree={props.boolThree} boolFour={props.boolFour} boolFive={props.boolFive} boolSix={props.boolSix}/>
            <SideNav isHolding={props.isHolding} updateOpen={updateOpen} open={open} info={props.info} infoCollapsed={props.infoCollapsed}/>
        </>
    );
}

export default Navigation;