const TypesOfLocationEnum = {
    "CUSTOM_EXAM": "CUSTOM_EXAM",
    "ZIP": "ZIP",
    "PDF": "PDF",
    "SETTINGS" : "SETTINGS",
    "TESTS": "TESTS",
    "DOCKER": "DOCKER",
    "ERROR": "ERROR"
}

export default TypesOfLocationEnum