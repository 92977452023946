import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Tippy from "@tippyjs/react";
import { useLocation, useNavigate } from "react-router-dom";

const ContextBlockEdit = ({ updateQuestions, questionsToSave, updateQuestionsToSave, provided, updateDeleteQuestion, index, points, question, uuid, questionType, bankUuid }) => {
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType
    });
    const navigate = useNavigate();
    const location = useLocation();

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        let body = {};
        body.fields = {}

        // check if we have something previous
        if (form.uuid in questionsToSave) {
            body.fields = questionsToSave[form.uuid].fields
        }

        body.uuid = uuid
        body.questionType = questionType

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { question, points, uuid, questionType }[key]) {
                if (key !== "uuid" || key !== "questionType") {
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        submitForm();
    }, [form]);

    useEffect(() => {
        updateQuestions((prev) => 
            prev.map((question) => {
                if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
                    return {
                        ...question,
                        questions: question.questions.map((nestedQuestion) => {
                            if (nestedQuestion.uuid === form.uuid) {
                                return form;
                            }
                            return nestedQuestion;
                        }),
                    };
                } else if (question.uuid === form.uuid) {
                    return form;
                }
                return question;
            })
        );
    }, [form]);

    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('uuid') === uuid) {
            searchParams.set("uuid", ""); 
        } else {
            searchParams.set("uuid", uuid);
        }
        
        navigate(`?${searchParams.toString()}`);
    }

    const currentId = new URLSearchParams(location.search).get('uuid');
    
    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        <Grid provided={provided}/>
                        C{ index }
                        <Tippy content={uuid === currentId ? "Deselect Question" : "Select Question"} placement="right">
                            <input type={"checkbox"} className="check-auto" checked={uuid === currentId} onChange={() => {setNewParams()}} style={{ cursor: "pointer" }}/>
                        </Tippy>
                        <Tippy content={"Context data has ≈400KB max"} placement={"top"} theme="custom-tooltip">
                            <div className="docs-wrapper-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                    <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                            </div>
                        </Tippy>
                    </div>
                    <div className="pts">
                        <>                                           
                           { form.points } pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Enter context (render Markdown and LaTeX)" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
            </div>
        </>
    );
}

export default ContextBlockEdit;