import React, { useRef, useEffect } from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import CustomExamWrapperEdit from "./customExamWrapperEdit";
import AddAQuestionForm from "./addAQuestionForm";
import WhiteLoading from "./whiteLoading";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";

const CustomProgrammingExam = ({ updateScrollAmount, scrollAmount, saveAnswersCritical, updateQuestions, assessmentInformation, dockerfileAndFunctionExists, ignoredPrefixes, requestLifeCycleTwo, updateContents, updateIsAllowedToScoll, isAllowedToScroll, isDarkTheme, updateIsDarkTheme, contents, testsConfigDict, ignoredPaths, requestLifeCycle, questions, questionsToSave, updateQuestionsToSave, isEditing, addAQuestion, updateIsEditing, updateAddAQuestion, updateDeleteQuestion, useSyntaxHighlighting, zipFileExists }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && scrollAmount !== null && scrollAmount !== undefined) {
            ref.current.scrollTo({ top: scrollAmount, behavior: "instant" });
        }

    }, [])

    return (
        <>
            {
                requestLifeCycle

                ?

                <div className="loading-zip-container">
                    <WhiteLoading />
                </div>

                :

                    <div ref={ref} className="custom-programming-exam-wrapper" style={{ position: "relative" }} onScroll={(e) => {
                        updateScrollAmount(e.target.scrollTop);                            
                    }}>
                        {
                            isEditing
        
                            ?
        
                            <>
                                <CustomExamWrapperEdit saveAnswersCritical={saveAnswersCritical} updateQuestions={updateQuestions} assessmentInformation={assessmentInformation} dockerfileAndFunctionExists={dockerfileAndFunctionExists} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} ignoredPrefixes={ignoredPrefixes} updateIsAllowedToScoll={updateIsAllowedToScoll} isAllowedToScroll={isAllowedToScroll} questionsToSave={questionsToSave} ignoredPaths={ignoredPaths} testsConfigDict={testsConfigDict} updateQuestionsToSave={updateQuestionsToSave} contents={contents} zipFileExists={zipFileExists} useSyntaxHighlighting={useSyntaxHighlighting} questions={questions} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} updateDeleteQuestion={updateDeleteQuestion}/>
                            </>
        
                            :
        
                            <>
                                <MathJaxContext config={mathJaxConfig}>
                                    <CustomExamWrapperView ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} updateContents={updateContents} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} questionsToSave={questionsToSave} useSyntaxHighlighting={useSyntaxHighlighting} contents={contents} questions={questions} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} grading={false}/>
                                </MathJaxContext>
                            </>
                        }
                    </div>                         

            }
            { addAQuestion ? <AddAQuestionForm updateIsAllowedToScoll={updateIsAllowedToScoll} updateQuestions={updateQuestions} updateQuestionsToSave={updateQuestionsToSave} updateAddAQuestion={updateAddAQuestion} questions={questions}/> : <></> }
        </>
    );
}

export default CustomProgrammingExam;