import React from "react";

const ShowError = ({ error, full }) => {
    return (
        <>
            <div className={full ? "error-wrapper-container-full" : "error-wrapper-container"}>
                <div className="error-wrapper">
                    {error}
                </div>
            </div>
        </>
    );
}

export default ShowError;