import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';

const SmallBoxPlots = (props) => {
    useEffect(() => {
        // First chart: Grades
        const gradesTrace = {
            y: props.gradesData,
            type: 'box',
            name: 'Grades (points)',
            marker: { color: 'rgba(01, 105, 244, 255)' },
        };

        const gradesLayout = {
            title: 'Grades Distribution',
            margin: { t: 60, b: 60, l: 60, r: 60 },
            showlegend: false
        };

        Plotly.newPlot('gradesDiv', [gradesTrace], gradesLayout, { displayModeBar: false });

        // Second chart: Time
        const timeTrace = {
            y: props.timeData,
            type: 'box',
            name: 'Time (min)',
            marker: { color: 'rgba(106, 90, 205, 1)' },
        };

        const timeLayout = {
            title: 'Time Spent Distribution',
            margin: { t: 60, b: 60, l: 60, r: 60 },
            showlegend: false
        };

        Plotly.newPlot('timeDiv', [timeTrace], timeLayout, { displayModeBar: false });
    }, [props.gradesData, props.timeData]);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: "100%", flexDirection: 'column', rowGap: "20px" }}>
            <div id="gradesDiv" style={{ width: '100%', height: '400px' }}></div>
            <div id="timeDiv" style={{ width: '100%', height: '400px' }}></div>
        </div>
    );
};

export default SmallBoxPlots;