import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Explanation from "./explanation";
import Tippy from "@tippyjs/react";
import { useLocation, useNavigate } from "react-router-dom";
import RunNewTestsShortResponse from "./runNewTestsShortResponse";

const TextResponseEdit = ({ saveAnswersCritical, lastStartTime, autoAssign, testStudentResponse, results, status, attempt, isHarsh, modelResponse, useAi, explanation, useExplanation, updateQuestions, questionsToSave, showDrag, updateQuestionsToSave, provided, updateDeleteQuestion, index, points, question, uuid, questionType, bankUuid }) => {
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType,
        useExplanation: useExplanation,
        explanation: explanation,
        useAi: useAi,
        modelResponse: modelResponse,
        isHarsh: isHarsh,
        testStudentResponse: testStudentResponse,
        autoAssign: autoAssign
    });
    const navigate = useNavigate();
    const location = useLocation();

    function updateForm(key, value) {
        if (form.useAi && (key === "testStudentResponse" || key === "modelResponse" || key === "question") && value?.length > 2000) {            
            return;
        }
        
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        let body = {};
        body.fields = {}

        // check if we have something previous
        if (form.uuid in questionsToSave) {
            body.fields = questionsToSave[form.uuid].fields
        }

        body.uuid = uuid
        body.questionType = questionType

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { points, question, uuid, questionType, autoAssign, testStudentResponse, isHarsh, modelResponse, useExplanation, explanation, useAi }[key]) {
                if (key !== "uuid" || key !== "questionType") {
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        submitForm();
    }, [form])

    useEffect(() => {
        updateQuestions((prev) => 
            prev.map((question) => {
                if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
                    return {
                        ...question,
                        questions: question.questions.map((nestedQuestion) => {
                            if (nestedQuestion.uuid === form.uuid) {
                                return form;
                            }
                            return nestedQuestion;
                        }),
                    };
                } else if (question.uuid === form.uuid) {
                    return form;
                }
                return question;
            })
        );
    }, [form]);

    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('uuid') === uuid) {
            searchParams.set("uuid", ""); 
        } else {
            searchParams.set("uuid", uuid);
        }
        
        navigate(`?${searchParams.toString()}`);
    }

    const currentId = new URLSearchParams(location.search).get('uuid');

    useEffect(() => {
        updateQuestions((prev) => 
            prev.map((question) => {
                if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
                    return {
                        ...question,
                        questions: question.questions.map((nestedQuestion) => {
                            if (nestedQuestion.uuid === form.uuid) {
                                return {
                                    ...form,
                                    results,
                                    attempt,
                                    status,
                                    lastStartTime
                                };
                            }
                            return nestedQuestion;
                        }),
                    };
                } else if (question.uuid === form.uuid) {
                    return {
                        ...form,
                        results,
                        attempt,
                        status,
                        lastStartTime
                    };
                }
                return question;
            })
        );
    }, [form]);
    
    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                        <Tippy content={uuid === currentId ? "Deselect Question" : "Select Question"} placement="right">
                            <input type={"checkbox"} className="check-auto" checked={uuid === currentId} onChange={() => {setNewParams()}} style={{ cursor: "pointer" }}/>
                        </Tippy>
                        <Tippy content={"Question data has ≈400KB max"} placement={"top"} theme="custom-tooltip">
                            <div className="docs-wrapper-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                    <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                            </div>
                        </Tippy>
                    </div>
                    <div className="pts">
                        <>
                            <input onBlur={submitForm} className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder={`Enter question (render Markdown and LaTeX${ form.useAi ? " with a 2,000 character max" : ""})`} style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="container-zip-location">
                    <div className="message-container" style={{ alignItems: "center" }}>
                        <div className="message-container" style={{ alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"/>
                            </svg>
                            AI-Assisted Grading:
                        </div>
                        <input style={{ cursor: "pointer" }} className="check-auto" type="checkbox" checked={form.useAi} onChange={(e) => updateForm("useAi", e.target.checked)} />
                    </div>
                </div>
                {
                    form.useAi ? 

                    <>
                        <div className="container-zip-location">
                            <div className="message-container" style={{ alignItems: "center", textWrap: "nowrap" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
                                    </svg>
                                    Harsh:
                                    <input style={{ cursor: "pointer" }} className="check-auto" type="checkbox" checked={form.isHarsh} onChange={(e) => updateForm("isHarsh", e.target.checked)} />
                                    <Tippy content={"Harshness should be on to grade near exact comparisons"} placement={"top"} theme="custom-tooltip">
                                        <div className="docs-wrapper-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                            </svg>
                                        </div>
                                    </Tippy>
                                </div>                                
                            </div>
                        </div>
                        <div className="container-zip-location">
                            <div className="message-container" style={{ alignItems: "center", textWrap: "nowrap" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
                                    </svg>
                                    Auto-Assign:
                                    <input style={{ cursor: "pointer" }} className="check-auto" type="checkbox" checked={form.autoAssign} onChange={(e) => updateForm("autoAssign", e.target.checked)} />
                                    <Tippy content={"Auto-assign AI score or require manual approval/edits"} placement={"top"} theme="custom-tooltip">
                                        <div className="docs-wrapper-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                            </svg>
                                        </div>
                                    </Tippy>
                                </div>                                
                            </div>
                        </div>
                    </>
                    
                    :

                    <>
                    </>

                }
                {
                    form.useAi ? 

                    <>
                        <div className="container-zip-location">
                            <div className="message-container" style={{ alignItems: "center", textWrap: "nowrap" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    Model Response:
                                </div>
                            </div>
                        </div>
                        <div className="text-response-area">
                        <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Model response that will be compared against students' answers: 2,000 character max (may be blank, which will cause the answer to be evaluated on the AI's general knowledge)" style={{ minHeight: "50px" }} value={form.modelResponse} onChange={(e) => {
                                updateForm("modelResponse", e.target.value);
                            }}/>
                        </div>
                        <div className="container-zip-location">
                            <div className="message-container" style={{ alignItems: "center", textWrap: "nowrap" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177z"/>
                                        </svg>
                                    </div>
                                    Test Student Response:
                                </div>
                            </div>
                        </div>
                        <div className="text-response-area">
                        <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Test a student answer: 2,000 character max" style={{ minHeight: "50px" }} value={form.testStudentResponse} onChange={(e) => {
                                updateForm("testStudentResponse", e.target.value);
                            }}/>
                        </div>
                    </>
                    
                    :

                    <>
                    </>

                }        
                {
                    form.useAi && (
                        <div className="select-all-that-apply-array">
                            <RunNewTestsShortResponse saveAnswersCritical={saveAnswersCritical} updateQuestions={updateQuestions} lastStartTime={lastStartTime} updateQuestionsToSave={updateQuestionsToSave} questionsToSave={questionsToSave} points={points} status={status} results={results} attempt={attempt} uuid={uuid}/>                 
                        </div>
                    )
                }
                <div className="select-all-that-apply-array">
                    <Explanation form={form} updateForm={updateForm} submitForm={submitForm}/>
                </div>
            </div>
        </>
    );
}

export default TextResponseEdit;