const keyValueUpdate = (prev, uuid, key, value) => {
    return {
        ...prev,
        gradedQuestions: prev.gradedQuestions.map((question) => {
            if (question.uuid !== uuid) return question;

            return {
                ...question,
                [key]: value
            }
        })
    }
}

export default keyValueUpdate;