function validateDockerfile(inputStr) {
    const lines = inputStr.split('\n');

    const checkLineForComment = (line, target) => {
        return !line.trim().startsWith('#') && line.includes(target);
    };

    if (!lines.some(line => checkLineForComment(line, 'FROM public.ecr.aws/lambda/python:3.12'))) {
        return [false, "Error: The 'FROM' line should be 'FROM public.ecr.aws/lambda/python:3.12'"];
    }

    if ((inputStr.match(/FROM/gi) || []).length !== 1) {
        return [false, "Error: 'FROM' appears more than once in Dockerfile"];
    }

    if (!lines.some(line => checkLineForComment(line, 'COPY . ${LAMBDA_TASK_ROOT}'))) {
        return [false, "Error: The 'COPY' line should be 'COPY . ${LAMBDA_TASK_ROOT}'"];
    }

    if ((inputStr.match(/COPY/gi) || []).length !== 1) {
        return [false, "Error: 'COPY' appears more than once in Dockerfile"];
    }

    if (!lines.some(line => checkLineForComment(line, 'WORKDIR ${LAMBDA_TASK_ROOT}'))) {
        return [false, "Error: The 'WORKDIR' line should be 'WORKDIR ${LAMBDA_TASK_ROOT}'"];
    }

    if ((inputStr.match(/WORKDIR/gi) || []).length !== 1) {
        return [false, "Error: 'WORKDIR' appears more than once in Dockerfile"];
    }

    if (!lines.some(line => checkLineForComment(line, 'RUN dnf install -y bash'))) {
        return [false, "Error: Must include 'RUN dnf install -y bash'"];
    }

    if (!lines.some(line => checkLineForComment(line, 'RUN pip install -r requirements.txt'))) {
        return [false, "Error: Must include 'RUN pip install -r requirements.txt'"];
    }

    if (!lines.some(line => checkLineForComment(line, 'CMD ["lambda_function.lambda_handler"]'))) {
        return [false, "Error: The 'CMD' line should be 'CMD [\"lambda_function.lambda_handler\"]'"];
    }

    if ((inputStr.match(/CMD/gi) || []).length !== 1) {
        return [false, "Error: 'CMD' appears more than once in Dockerfile"];
    }

    if ((inputStr.match(/ENTRYPOINT/gi) || []).length !== 0) {
        return [false, "Error: 'ENTRYPOINT' should not appear in Dockerfile"];
    }

    return [true, null];
}

export default validateDockerfile;