import moment from 'moment-timezone';

const convertDate = (dateInput, deleteYearBool) => {
    if (!dateInput) {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path stroke="var(--almost-black)" strokeWidth={.5} d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/>
                </svg>
            </>
        );
    }
    // Parse the input date in the provided reference timezone
    const localTimeZone = moment.tz.guess();
    const localDate = moment.utc(dateInput).tz(localTimeZone);

    // Define options for formatting without commas
    let formatString = 'MMM D, h:mm A'; // Default format with no year
    if (!deleteYearBool) {
        formatString = 'MMM D YYYY, h:mm A'; // Include year if deleteYearBool is false
    }

    // Format the date
    let formattedDate = localDate.format(formatString);

    // Replace commas with 'at'
    formattedDate = formattedDate.replace(/,/g, ' at');

    // Return the formatted date
    return formattedDate;
};

export default convertDate;