import React, { useEffect } from "react";
import AddCourse from "./addCourse";
import Person from "./person";
import LogoFill from "./logoFill";
import AddInstructor from "./addInstructor";
import NextPrevious from "./nextPrevious";
import LeftChev from "./leftChev";
import RightChev from "./rightChev";
import DoubleLeftChev from "./doubleLeftChev";
import DoubleRightChev from "./doubleRightChev";
import useWindowWidth from "./useWindowWidth";
import AddProgrammingEval from "./addProgrammingEval";
import TopRightCustomExam from "./topRightCustomExam";
import { useNavigate, useParams } from "react-router-dom";

const Nav = (props) => {    
    const width = useWindowWidth(535);
    const navigate = useNavigate();
    const { courseCode, assessmentCode, email } = useParams();    

    const handleClick = async (type, idPassed, tabPassed) => {
        try {
            const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-next-submission-type/${courseCode}/${assessmentCode}/${type}/${email}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await response.json();
            if (response.status === 200) {         
                const query = `id=${idPassed ? idPassed : ""}&tab=${tabPassed}`
                
                if (data.email !== "") {
                    navigate(`/${courseCode}/${assessmentCode}/${data.email}?${query}`)
                    window.location.reload();
                } else {
                    navigate(`/${courseCode}/${assessmentCode}/${data.email}`)
                }

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="nav-container">
                <div className="basic-container">
                    <LogoFill open={props.open}/>
                    <Person open={props.open}/>
                </div>
                { props.bool && <AddCourse open={props.open}/> }
                {
                    props.boolTwo

                    ?

                    <div className="header-box">
                        { props.boolTwo && <AddProgrammingEval updateCreating={props.updateCreating} creating={props.creating} open={props.open}/> }
                    </div>

                    :

                    <>
                    </>
                }
                { props.boolThree && <AddInstructor open={props.open}/> }
                {
                    props.boolFive

                    ?

                    <div className="next-container">
                        {
                            width 

                            ?

                            <></>

                            :

                            <NextPrevious isCursorInside={props.isCursorInside} direction={'a'} handleClick={handleClick} tab={props.tab} id={props.id} type={"previousUngraded"} width={"50px"} leftToRight={true} text={"Previous Unfinished"} inverted={false} svg={<DoubleLeftChev />}/>

                        }
                        <NextPrevious isCursorInside={props.isCursorInside} direction={'s'} handleClick={handleClick} tab={props.tab} id={props.id} type={"previous"} width={"15px"} leftToRight={true} text={"Previous"} inverted={true} svg={<LeftChev />}/>
                        <NextPrevious isCursorInside={props.isCursorInside} direction={'d'} handleClick={handleClick} tab={props.tab} id={props.id} type={"next"} width={"15px"} leftToRight={false} text={"Next"} inverted={true} svg={<RightChev />}/>
                        {
                            width 

                            ?

                            <></>

                            :

                            <NextPrevious isCursorInside={props.isCursorInside} direction={'f'} handleClick={handleClick} tab={props.tab} id={props.id} type={"nextUngraded"} width={"50px"} leftToRight={false} text={"Next Unfinished"} inverted={false} svg={<DoubleRightChev />}/>

                        }
                    </div>

                    :

                    <>
                    </>
                }
                {
                    props.boolSix


                    ?

                    <>
                        <TopRightCustomExam updateText={props.updateText} text={props.text} saveAnswersCritical={props.saveAnswersCritical} updateQuestionsToSave={props.updateQuestionsToSave} questionsToSave={props.questionsToSave} updateIsEditing={props.updateIsEditing} isEditing={props.isEditing} updateAddAQuestion={props.updateAddAQuestion}/>
                    </>

                    :

                    <>
                    </>
                }
            </div>

        </>
    );
}

export default Nav;